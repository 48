import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="691" height="10" viewBox="0 0 691 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M129.13 9.45626C114.773 8.47721 9.62398 9.14203 1.98236 8.83526C-4.25578 8.43807 5.73878 1.86706 11.5339 0.914714C13.5652 0.572184 15.9238 0.529668 21.3268 0.70772C29.8938 1.03684 140.684 0.837013 140.684 0.837013C142.146 1.04565 146.537 0.374466 147.342 0.625287C149.878 1.21286 329.452 1.05901 344.767 1.08134C354.052 1.09507 346.706 1.46333 357.633 1.34299C380.921 1.10736 431.671 1.59191 449.316 1.29184C463.438 1.0429 466.483 1.04096 480.036 1.33047C493.887 1.5956 567.538 1.54431 575.959 1.10991C578.019 0.99002 579.601 1.04012 579.601 1.18636C579.601 1.21816 631.516 1.5097 631.755 1.625C632.142 1.77261 633.724 1.84814 635.187 1.75157C637.905 1.60858 693.166 1.25068 694.03 1.67975C696.743 2.91014 689.112 8.08429 682.689 9.3269C676.116 10.658 640.058 9.01095 611.067 9.72237L476.097 9.32806L447.167 9.73449C443.854 9.28406 431.103 10.3453 423.403 9.5297C420.15 9.20665 397.163 9.0809 392.087 9.3936C387.667 9.63231 270.075 8.57456 258.011 9.41574C236.519 8.60222 149.341 9.34965 129.13 9.45626Z" fill="#FFA700" />
    </svg>
  </span>
)
