/* eslint-disable no-undef */
import React from "react"
import data from "@components/pageTicker/pagePrice/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import ComparePrice from "@components/pageTicker/pagePrice/components/comparePrice/comparePrice"
import Question from "@components/pageTicker/pagePrice/components/question/question"

const TemplatePageTickerPrice = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/"
          location={location}
          windowsWidth={windowsWidth}
        />
        <div className="container">
          <ComparePrice data={data.pricing} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Estas empresas ya mejoran la comunicación con sus clientes con Beex" />
          <Question data={data.question} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageTickerPrice
