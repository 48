import React from "react"
import { TitleUnderlineOne } from "./titleUnderline"


const BannerVideo = ({ data, }) => {
    return (
        <section className="container-banner-ponent">
               <p className="container-operations-webinar-title-mobile container-operations-webinar-title">
                    <TitleUnderlineOne underline="PONENTE" />
                </p>
            <img
                className="container-banner-ponent-info-image"
                srcSet={data.image}
                alt="suport"
                loading="lazy"
            />
            <section className="container-banner-ponent-section">
                <p className="container-operations-webinar-title-desktop container-operations-webinar-title">
                    <TitleUnderlineOne underline="PONENTE" />
                </p>
                {data.logo}
                <p className="container-banner-ponent-name">
                    {data.nombre}
                </p>
                <p className="container-banner-ponent-puesto">
                    {data.puesto}
                </p><p className="container-banner-ponent-description">
                    {data.description}
                </p>
            </section>
        </section>
    )
}
export default BannerVideo
