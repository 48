/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageIndustrias/pageSalud/data.jsx"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageIndustrias/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageIndustrias/pageSalud/componentExperience/experience"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageIndustrias/pageSalud/titleUnderline/titleUnderlineAccordionLeft"
import { TitleUnderlineCovertionsOne, TitleUnderlineCovertionsTwo } from "@components/pageIndustrias/pageSalud/titleUnderline/titleUnderlineConvertions"
import TitleUnderlineTestimony from "@components/pageIndustrias/pageSalud/titleUnderline/titleUnderlineTestimony"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Conversions from "@components/pageIndustrias/components/conversions/conversions"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"

const TemplatePageContactCenter = ({ location }) => {

    //Video youtube intro
    const [videoIntro, setVideoIntro] = useState(false)
    const openVideoIntro = value => {
        setVideoIntro(value)
    }

    //Video youtube
    const [video, setVideo] = useState(false)
    const openVideo = value => {
        setVideo(value)
    }

    //velocidad de entrada de textos y animaciones para desktop y mobile
    const windowsWidth = true


    const titleAccordionRight = <p>
        Tenemos las mejores herramientas para brindar un
        servicio de <TitleUnderlineLeftOne underline="calidad a" /> <TitleUnderlineLeftTwo underline="tus pacientes" />
    </p>
    const titleTestimony = <p>
        Beex acompaña a diferentes instituciones del sector salud a crear <TitleUnderlineTestimony underline="experiencias memorables" />
    </p>
    const titleConvertions = <p className="container-conversions-title">
        Potenciamos las operaciones de  <TitleUnderlineCovertionsOne underline="diferentes" /> <TitleUnderlineCovertionsOne underline="subsectores" />  de salud para mejorar las interacciones con los pacientes  </p>

    const titleExperience = <p className="container-experience-ecommerce-title">
        Te ayudamos a construir <TitleUnderlineCovertionsTwo underline="relaciones de" />  <TitleUnderlineCovertionsOne underline="confianza" />   con todos tus pacientes
    </p>



    const iconsSilder = i => {
        switch (i) {
            case 0:
                return finanzas
            case 1:
                return ecommerce
            default:
                return ""
        }
    }
    const titleSilder = i => {
        switch (i) {
            case 0:
                return "Finanzas"
            case 1:
                return "Ecommerce"
            default:
                return "Conoce otros casos de éxito"
        }
    }

    return (
        <div className="fnd">
            <section>
                <BannerCookies />
                <Header
                    path="/soluciones/"
                    location={location}
                    windowsWidth={windowsWidth}
                />
                {videoIntro ?
                    <>
                        <button
                            className="button-video-youtube-beex"
                            onClick={() => {
                                openVideoIntro(false)
                            }}
                        >
                            X
                        </button>
                        <div className="container-video-youtube-beex">
                            <div className="container-video-youtube-beex-background"></div>
                            <div className="container-video-youtube-beex-iframe">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/vAaZZM0Gm5Q?autoplay=1"
                                    title="Beex Teams"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </> : ""}
                {video ?
                    <>
                        <button
                            className="button-video-youtube-beex"
                            onClick={() => {
                                openVideo(false)
                            }}
                        >
                            X
                        </button>
                        <div className="container-video-youtube-beex">
                            <div className="container-video-youtube-beex-background"></div>
                            <div className="container-video-youtube-beex-iframe">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/vAaZZM0Gm5Q?autoplay=1"
                                    title="Beex Teams"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </> : ""}


                <div className="container">
                    <Start data={data.start} location={location} imageViewResponsive={false} openVideo={openVideoIntro} />
                    <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Únete a las empresas del sector salud que lograron mejorar la conexión con sus pacientes" />
                    <AccordionLeft data={data.accordionRight} title={titleAccordionRight} description={""} />
                    <BannerCTA
                        title="Mejora el tiempo de respuesta a consultas de tus pacientes"
                        text="Aumenta la confianza de tus usuarios mediante la automatización de contacto por WhatsApp, voz, video y más."
                        link="/hablemos/"
                        textButton="Hablar con un experto"
                    />
                    <Testimonies data={data.testimonies}
                        location={location}
                        title={titleTestimony}
                        iconsSilder={iconsSilder}
                        titleSilder={titleSilder}
                        buttonSilder={true} />
                    <Conversions data={data.conversions} title={titleConvertions} />
                    <Experience data={data.modules} title={titleExperience} />
                    <BannerCTA
                        title="Reduce el promedio de tiempo de espera por atención en el sector salud"
                        text="Agiliza las atenciones de tus pacientes y brinda experiencias únicas. Conoce como, hablando con un experto."
                        link="/hablemos/"
                        textButton="Hablar con un experto"
                    />
                    <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
                    <ArticleBlog data={data.articles} home={true} />
                    <BannerContact data={data.contacts} location={location} />
                    <Footer data={data.footer} location={location} />
                    <FooterMobile data={data.footer} location={location} />
                </div>
            </section>
        </div>
    )
}

export default TemplatePageContactCenter
