import React from "react"

export const TitleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="206" height="10" viewBox="0 0 206 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.2986 10C34.0397 9.07169 2.85454 10.1081 0.587823 9.82831C-1.26274 9.45317 1.69462 2.84689 3.41237 1.87407C4.01447 1.52436 4.71396 1.47351 6.3166 1.63247C8.85781 1.93131 41.7164 1.33997 41.7164 1.33997C42.1503 1.54344 43.4519 0.856744 43.6909 1.10472C44.4436 1.68332 97.7026 0.894891 102.245 0.8631C104.999 0.844025 102.82 1.23824 106.061 1.07928C112.968 0.761364 128.02 1.06657 133.253 0.704142C137.441 0.405302 138.344 0.392594 142.364 0.63421C146.473 0.850392 168.316 0.538832 170.813 0.074676C171.424 -0.0524901 171.893 -0.00797814 171.893 0.138263C171.893 0.170054 187.291 0.278142 187.362 0.392591C187.477 0.538832 187.946 0.608769 188.38 0.507036C189.186 0.354437 205.576 -0.198737 205.832 0.227269C206.638 1.44806 204.38 6.64915 202.477 7.91446C200.529 9.26878 189.832 7.74915 181.235 8.56302L141.204 8.64568L132.624 9.15434C131.641 8.71561 127.861 9.82196 125.576 9.03353C124.611 8.72197 117.793 8.67745 116.288 9.00809C114.978 9.26242 80.1001 8.62023 76.523 9.50403C70.1478 8.76647 44.293 9.82197 38.2986 10Z" fill="#FFA700" />
    </svg>
  </span>
)