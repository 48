import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.png"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.png"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.png"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageFourMobile from "./images/imagesMobile/imageThree.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Recontacto a leads no interesados",
      description: "Cuando un lead no responde inicialmente, puedes configurar flujos automatizados con Beex para enviar mensajes de recontacto en momentos clave. Por ejemplo, una promoción personalizada o información detallada sobre un plan de salud puede motivar una respuesta. <br /><br /> Estos workflows aseguran un seguimiento constante sin depender de intervenciones manuales, maximizando las oportunidades de conversión y manteniendo a los leads comprometidos con tu oferta.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Chatbot para preguntas frecuentes",
      description: "Durante la fase de investigación, los leads suelen tener preguntas que, si no se resuelven rápidamente, pueden llevar al abandono de la consulta. Con Beex, puedes implementar chatbots diseñados para responder a las dudas más comunes en tiempo real. <br /><br />Esto no solo mejora la experiencia del cliente, sino que también libera a los agentes para enfocarse en casos más complejos, ayudando a mantener el interés del lead y guiándolo hacia la conversión.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Integración con sistemas de registros de salud",
      description: "Beex permite conectar bases de datos como ERP o CRM para gestionar campañas más eficientes. Estas integraciones hacen posible el uso de marcadores predictivos para llamadas personalizadas o el envío masivo de mensajes por WhatsApp y correo electrónico. <br /><br /> Con esta funcionalidad, puedes realizar campañas altamente segmentadas y efectivas, asegurando que los contactos sean relevantes y aumenten las probabilidades de éxito.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-pandero">
      <p className="container-experience-pandero-title">
        Sanitas, te ayudamos a crear experiencias únicas que permitan      <TitleUnderlineOne underline="transformar tus" /> <TitleUnderlineTwo underline="leads en" />         <TitleUnderlineOne underline="afiliados finales" />
      </p>


      <div className="container-experience-pandero-cardsOne">
        <div>
          <section className="container-experience-pandero-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 6px 32px" }}>
            <section style={{ padding: "0px 26px" }}>
              <p className="container-experience-pandero-cardsOne-card-title">Recontacto a leads no interesados </p>
              <p className="container-experience-pandero-cardsOne-card-description">
                Cuando un lead no responde inicialmente, puedes configurar flujos automatizados con Beex para enviar mensajes de recontacto en momentos clave. Por ejemplo, una promoción personalizada o información detallada sobre un plan de salud puede motivar una respuesta.
                <br /><br />
                Estos workflows aseguran un seguimiento constante sin depender de intervenciones manuales, maximizando las oportunidades de conversión y manteniendo a los leads comprometidos con tu oferta.
              </p>
            </section>
            <section>
              <img
                className="container-experience-pandero-cardsOne-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        <div className="container-experience-pandero-cardsTwo">
          <div className="container-experience-pandero-cardsOne-column">
            <section className="container-experience-pandero-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
              <section>
                <p className="container-experience-pandero-cardsOne-card-title">Chatbot para preguntas frecuentes</p>
                <p className="container-experience-pandero-cardsOne-card-description">
                  Durante la fase de investigación, los leads suelen tener preguntas que, si no se resuelven rápidamente, pueden llevar al abandono de la consulta. Con Beex, puedes implementar chatbots diseñados para responder a las dudas más comunes en tiempo real.
                  <br /><br />
                  Esto no solo mejora la experiencia del cliente, sino que también libera a los agentes para enfocarse en casos más complejos, ayudando a mantener el interés del lead y guiándolo hacia la conversión.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsOne-card-image"
                  src={imageTwoDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
          <div className="container-experience-pandero-cardsTwo-cards-column">
            <section className="container-experience-pandero-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 6px" }}>
              <section>
                <p className="container-experience-pandero-cardsTwo-card-title">Integración con sistemas de registros de salud</p>
                <p className="container-experience-pandero-cardsTwo-card-description">
                  Beex permite conectar bases de datos como ERP o CRM para gestionar campañas más eficientes. Estas integraciones hacen posible el uso de marcadores predictivos para llamadas personalizadas o el envío masivo de mensajes por WhatsApp y correo electrónico.
                  <br /><br />
                  Con esta funcionalidad, puedes realizar campañas altamente segmentadas y efectivas, asegurando que los contactos sean relevantes y aumenten las probabilidades de éxito.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsTwo-card-image"
                  src={imageThreeDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
        </div>
      </div>


      <div className="container-experience-pandero-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-pandero-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-pandero-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-pandero-cards-mobile-card-description" dangerouslySetInnerHTML={{ __html: elemento.description }}>

                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-pandero-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
