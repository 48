import React from "react"
import { Link } from "gatsby"

const IntroHome = ({ data, location, imageViewResponsive, background}) => {
  return (
    <section className="container-intro-page-casos-de-uso" style={{ background: background }}>
      <div className="container-intro-page-casos-de-uso-infoTitle">
        <h1 className="container-intro-page-casos-de-uso-infoTitle-title">
          {data.title}
        </h1>
        <div className="container-intro-page-casos-de-uso-infoTitle-text">
          {data.textbody}
        </div>
        <div className="container-intro-page-casos-de-uso-infoTitle-button">
          <Link
            className="container-intro-page-casos-de-uso-infoTitle-buttonOne"
            to={`/hablemos/${location.search}`}
          >
            {data.textbutton}
          </Link>
        </div>
      </div>
      <div className={`${imageViewResponsive ? "" : "container-intro-page-casos-de-uso-image-hidden"} container-intro-page-casos-de-uso-image `}>
        <img
          src={data.image}
          alt="customer"
          loading="lazy"
        />
      </div>
    </section>
  )
}

export default IntroHome
