import React, { useState, useEffect } from 'react';
import FotoGrupo from "@components/pageWebinar/webinar/webinar1/image/start.png"
import FotoGrupoMobile from "@components/pageWebinar/webinar/webinar1/image/startMobile.png"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"

const Start = () => {


  const fechaObjetivo = new Date('2025-01-31T14:00:00');

  // Estado para almacenar el tiempo restante
  const [tiempoRestanteDias, setTiempoRestanteDias] = useState('');
  const [tiempoRestanteHoras, setTiempoRestanteHoras] = useState('');
  const [tiempoRestanteMinutos, setTiempoRestanteMinutos] = useState('');

  // Función para calcular el tiempo restante
  const calcularTiempoRestante = () => {
    const ahora = new Date();
    const diferencia = fechaObjetivo - ahora;

    // Si la fecha ya pasó, retornar un mensaje
    if (diferencia <= 0) {
      setTiempoRestanteDias('')
 setTiempoRestanteHoras('')
 setTiempoRestanteMinutos('')
      return;
    }

    // Calcular días, horas, minutos y segundos
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));

    // Establecer el formato en el estado
    setTiempoRestanteDias(dias)
    setTiempoRestanteHoras(horas)
    setTiempoRestanteMinutos(minutos);
  };

  useEffect(() => {
    // Calcular el tiempo inicial
    calcularTiempoRestante();

    // Establecer un intervalo para actualizar cada minuto
    const intervaloId = setInterval(calcularTiempoRestante, 60000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervaloId);
  }, []);


  return (
    <section className="container-intro-page-webinar">
      <section className="container-intro-page-webinar-section">
        <p className="container-intro-page-webinar-title">
          <TitleUnderlineOne underline="Nuevas" />  <TitleUnderlineTwo underline="funcionalidades" /> de WhatsApp Business API en el 2025
        </p>
        <p className="container-intro-page-webinar-date">31 de enero - | 14:00hrs </p>
        <div className="container-intro-page-webinar-section-date">
        <p className="container-intro-page-webinar-section-date-item">{tiempoRestanteDias + " " + "dias"}</p>
        <p className="container-intro-page-webinar-section-date-item">{tiempoRestanteHoras  + " " + "horas" }</p>
        <p className="container-intro-page-webinar-section-date-item">{tiempoRestanteMinutos + " " + "minutos" }</p>
        </div>
      </section>
      <img
        loading="lazy"
        className="container-intro-page-webinar-image"
        src={FotoGrupo}
        alt="fotos"
      />
       <img
        loading="lazy"
        className="container-intro-page-webinar-image-mobile"
        src={FotoGrupoMobile}
        alt="fotos"
      />
    </section>

  )
}

export default Start
