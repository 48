import React, { useState, useEffect } from 'react';

const CtaEbook = () => {
  const fechaObjetivo = new Date('2025-01-31T14:00:00');

  // Estado para almacenar el tiempo restante
  const [tiempoRestanteDias, setTiempoRestanteDias] = useState('');
  const [tiempoRestanteHoras, setTiempoRestanteHoras] = useState('');
  const [tiempoRestanteMinutos, setTiempoRestanteMinutos] = useState('');

  // Función para calcular el tiempo restante
  const calcularTiempoRestante = () => {
    const ahora = new Date();
    const diferencia = fechaObjetivo - ahora;

    // Si la fecha ya pasó, retornar un mensaje
    if (diferencia <= 0) {
      setTiempoRestanteDias('')
      setTiempoRestanteHoras('')
      setTiempoRestanteMinutos('')
      return;
    }

    // Calcular días, horas, minutos y segundos
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));

    // Establecer el formato en el estado
    setTiempoRestanteDias(dias)
    setTiempoRestanteHoras(horas)
    setTiempoRestanteMinutos(minutos);
  };

  useEffect(() => {
    // Calcular el tiempo inicial
    calcularTiempoRestante();

    // Establecer un intervalo para actualizar cada minuto
    const intervaloId = setInterval(calcularTiempoRestante, 60000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervaloId);
  }, []);


  return (
    <div className="ebook-banner">
      <div className="frejol">
        <svg width="69" height="31" viewBox="0 0 69 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M63.1623 14.5338C75.385 -0.151989 64.0248 -21.772 44.7293 -15.8132C36.6913 -11.833 29.3808 -7.12199 19.8419 -5.51636C0.183093 -5.62964 -6.98882 16.2446 10.6066 26.2445C16.302 29.4811 24.1311 30.5664 31.1171 30.3283C49.8923 27.0337 53.4159 26.2445 63.1623 14.5338Z" fill="url(#paint0_linear_27594_2010)" />
          <defs>
            <linearGradient id="paint0_linear_27594_2010" x1="61.3957" y1="19.2444" x2="8.35675" y2="-7.85187" gradientUnits="userSpaceOnUse">
              <stop stop-color="#D20756" stop-opacity="0.58" />
              <stop offset="1" stop-color="#F7D069" stop-opacity="0.59" />
            </linearGradient>
          </defs>
        </svg>
        <svg width="67" height="40" viewBox="0 0 67 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M49.377 48.8227C68.2008 45.5469 73.5446 21.7157 54.9824 13.7614C46.2799 11.5892 37.6586 10.445 29.3501 5.49149C14.4432 -7.32514 -5.18685 4.69903 1.74549 23.7131C3.98951 29.8677 9.25257 35.7645 14.7303 40.1069C31.1706 49.7545 34.3667 51.4349 49.377 48.8227Z" fill="url(#paint0_linear_27594_2023)" />
          <defs>
            <linearGradient id="paint0_linear_27594_2023" x1="44.9804" y1="51.2682" x2="22.1107" y2="-3.72554" gradientUnits="userSpaceOnUse">
              <stop stop-color="#D20756" stop-opacity="0.75" />
              <stop offset="1" stop-color="#F7D069" stop-opacity="0.82" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="banner-ebook-desktop">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_27594_2040)">
            <g filter="url(#filter0_d_27594_2040)">
              <path d="M17.9313 8.61402C17.2817 8.34552 16.5408 8.49177 16.0435 8.98902L15.715 9.31752C15.3903 8.53452 14.8075 7.88502 14.0732 7.47402C14.797 6.79002 15.2493 5.82177 15.2493 4.74927C15.2493 2.68152 13.567 0.999268 11.4992 0.999268C10.2737 0.999268 9.184 1.59027 8.49925 2.50152C7.8145 1.58952 6.72475 0.999268 5.49925 0.999268C3.43225 1.00002 1.75 2.68227 1.75 4.75002C1.75 5.82177 2.20225 6.79077 2.926 7.47477C1.77775 8.11677 1 9.34452 1 10.75V15.25C1 17.3178 2.68225 19 4.75 19H12.25C13.8108 19 15.1518 18.0415 15.7157 16.6825L16.0443 17.011C16.3765 17.3433 16.8175 17.5195 17.2668 17.5195C17.4902 17.5195 17.716 17.476 17.9313 17.3868C18.5808 17.1183 19 16.4898 19 15.787V10.2153C19 9.51177 18.58 8.88402 17.9313 8.61552V8.61402ZM11.5 2.50002C12.7405 2.50002 13.75 3.50952 13.75 4.75002C13.75 5.99052 12.7405 7.00002 11.5 7.00002C10.2595 7.00002 9.25 5.99052 9.25 4.75002C9.25 3.50952 10.2595 2.50002 11.5 2.50002ZM5.5 2.50002C6.7405 2.50002 7.75 3.50952 7.75 4.75002C7.75 5.99052 6.7405 7.00002 5.5 7.00002C4.2595 7.00002 3.25 5.99052 3.25 4.75002C3.25 3.50952 4.2595 2.50002 5.5 2.50002ZM14.5 15.25C14.5 16.4905 13.4905 17.5 12.25 17.5H4.75C3.5095 17.5 2.5 16.4905 2.5 15.25V10.75C2.5 9.50952 3.5095 8.50002 4.75 8.50002H12.25C13.4905 8.50002 14.5 9.50952 14.5 10.75V15.25ZM17.5 15.7855C17.5 15.9138 17.422 15.973 17.3568 16C17.2922 16.027 17.1955 16.0405 17.104 15.9498L16 14.8458V11.1543L17.104 10.0503C17.1955 9.95952 17.2922 9.97302 17.3568 10C17.422 10.027 17.5 10.0863 17.5 10.2145V15.7855Z" fill="url(#paint0_linear_27594_2040)" />
            </g>
          </g>
          <defs>
            <filter id="filter0_d_27594_2040" x="-3" y="-3.00073" width="26" height="26.0007" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.960784 0 0 0 0 0.827451 0 0 0 0 0.0705882 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27594_2040" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27594_2040" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_27594_2040" x1="10" y1="0.999268" x2="10" y2="19" gradientUnits="userSpaceOnUse">
              <stop stop-color="#F59A12" />
              <stop offset="1" stop-color="#F5D312" />
            </linearGradient>
            <clipPath id="clip0_27594_2040">
              <rect width="18" height="18" fill="white" transform="translate(1 1)" />
            </clipPath>
          </defs>
        </svg>
        <p className="text-banner-ebook">
          <strong>WEBINAR:</strong> Nuevas funcionalidades de WhatsApp Business API en el 2025 |
          <strong> QUEDAN: {tiempoRestanteDias} días | {tiempoRestanteHoras} horas | {tiempoRestanteMinutos} minutos</strong>
        </p>
        <a
          href="/webinar/gupshup"
          className="btn-banner-ebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          ¡Regístrate ahora!
        </a>
      </div>
      <div className="banner-ebook-mobile">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_27594_2040)">
            <g filter="url(#filter0_d_27594_2040)">
              <path d="M17.9313 8.61402C17.2817 8.34552 16.5408 8.49177 16.0435 8.98902L15.715 9.31752C15.3903 8.53452 14.8075 7.88502 14.0732 7.47402C14.797 6.79002 15.2493 5.82177 15.2493 4.74927C15.2493 2.68152 13.567 0.999268 11.4992 0.999268C10.2737 0.999268 9.184 1.59027 8.49925 2.50152C7.8145 1.58952 6.72475 0.999268 5.49925 0.999268C3.43225 1.00002 1.75 2.68227 1.75 4.75002C1.75 5.82177 2.20225 6.79077 2.926 7.47477C1.77775 8.11677 1 9.34452 1 10.75V15.25C1 17.3178 2.68225 19 4.75 19H12.25C13.8108 19 15.1518 18.0415 15.7157 16.6825L16.0443 17.011C16.3765 17.3433 16.8175 17.5195 17.2668 17.5195C17.4902 17.5195 17.716 17.476 17.9313 17.3868C18.5808 17.1183 19 16.4898 19 15.787V10.2153C19 9.51177 18.58 8.88402 17.9313 8.61552V8.61402ZM11.5 2.50002C12.7405 2.50002 13.75 3.50952 13.75 4.75002C13.75 5.99052 12.7405 7.00002 11.5 7.00002C10.2595 7.00002 9.25 5.99052 9.25 4.75002C9.25 3.50952 10.2595 2.50002 11.5 2.50002ZM5.5 2.50002C6.7405 2.50002 7.75 3.50952 7.75 4.75002C7.75 5.99052 6.7405 7.00002 5.5 7.00002C4.2595 7.00002 3.25 5.99052 3.25 4.75002C3.25 3.50952 4.2595 2.50002 5.5 2.50002ZM14.5 15.25C14.5 16.4905 13.4905 17.5 12.25 17.5H4.75C3.5095 17.5 2.5 16.4905 2.5 15.25V10.75C2.5 9.50952 3.5095 8.50002 4.75 8.50002H12.25C13.4905 8.50002 14.5 9.50952 14.5 10.75V15.25ZM17.5 15.7855C17.5 15.9138 17.422 15.973 17.3568 16C17.2922 16.027 17.1955 16.0405 17.104 15.9498L16 14.8458V11.1543L17.104 10.0503C17.1955 9.95952 17.2922 9.97302 17.3568 10C17.422 10.027 17.5 10.0863 17.5 10.2145V15.7855Z" fill="url(#paint0_linear_27594_2040)" />
            </g>
          </g>
          <defs>
            <filter id="filter0_d_27594_2040" x="-3" y="-3.00073" width="26" height="26.0007" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.960784 0 0 0 0 0.827451 0 0 0 0 0.0705882 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27594_2040" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27594_2040" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_27594_2040" x1="10" y1="0.999268" x2="10" y2="19" gradientUnits="userSpaceOnUse">
              <stop stop-color="#F59A12" />
              <stop offset="1" stop-color="#F5D312" />
            </linearGradient>
            <clipPath id="clip0_27594_2040">
              <rect width="18" height="18" fill="white" transform="translate(1 1)" />
            </clipPath>
          </defs>
        </svg>
        <p className="text-banner-ebook">
          <strong>WEBINAR:</strong> Nuevas funcionalidades de WhatsApp Business API en el 2025 |
          <strong> QUEDAN: {tiempoRestanteDias} días | {tiempoRestanteHoras} horas | {tiempoRestanteMinutos} minutos</strong>
        </p>
        <a
          className="link-banner-ebook-mobile"
          href="/webinar/gupshup"
          target="_blank"
          rel="noopener noreferrer"
        >
          ¡Regístrate ahora!
        </a>
      </div>
      <div className="frejol frejolTwo">
        <svg width="86" height="32" viewBox="0 0 86 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.90415 -14.3835C-1.32284 -5.89151 -2.65469 11.9166 7.16705 21.9861C24.9293 39.3415 55.5247 29.0102 74.6634 18.8397C103.473 0.112297 65.6941 -28.9679 42.9974 -26.4132C37.4402 -26.3413 32.6548 -25.2996 27.7126 -22.8454C21.7831 -19.9618 15.1265 -18.5308 9.90415 -14.3835Z" fill="url(#paint0_linear_27594_2011)" />
          <defs>
            <linearGradient id="paint0_linear_27594_2011" x1="24.2769" y1="-23.7186" x2="58.2193" y2="29.356" gradientUnits="userSpaceOnUse">
              <stop stop-color="#F7D069" stop-opacity="0.69" />
              <stop offset="1" stop-color="#D20756" stop-opacity="0.72" />
            </linearGradient>
          </defs>
        </svg>
        <svg width="77" height="32" viewBox="0 0 77 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.97472 51.4201C22.8293 66.4871 49.0345 72.8288 65.6327 60.4153C75.0142 52.0947 78.4421 37.4324 76.4575 25.2439C65.2718 -16.9439 -30.6637 -4.93521 9.97472 51.4201Z" fill="url(#paint0_linear_27594_2012)" />
          <defs>
            <linearGradient id="paint0_linear_27594_2012" x1="38.5" y1="0" x2="38.5" y2="67" gradientUnits="userSpaceOnUse">
              <stop stop-color="#F7D069" stop-opacity="0.69" />
              <stop offset="1" stop-color="#D20756" stop-opacity="0.72" />
            </linearGradient>
          </defs>
        </svg>

      </div>
    </div>
  )
}
export default CtaEbook
