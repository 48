import React from "react"
import StructurePageVideos from  "@components/pageRecursos/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Webinar de contact center, omnicanalidad y más de Beex Latam [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/videos/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Creamos videos para ayudar a las empresas a seguir creciendo en sus operaciones de atención al cliente, ventas, marketing y más."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Webinar de contact center, omnicanalidad y más de Beex Latam"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com//`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Creamos videos para ayudar a las empresas a seguir creciendo en sus operaciones de atención al cliente, ventas, marketing y más."
      />
    </Helmet>
    <StructurePageVideos location={location} recurso="webinar"/>
  </div>
)

export default IndexPage
