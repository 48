import React, { useState, useEffect } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"
import Select from "react-select"
import { Link } from "gatsby"


const URL_API = process.env.URL_API

const IntroHome = ({
  data,
  confirm,
  setConfirm,
  countries,
  landing
}) => {
  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  //Estado valores inputs
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    puesto: "",
    codeCountry: {
      value: "Perú",
      label: "Perú",
      image: "https://flagcdn.com/w320/pe.png",
      code: "+51",
    },
  })


  //Estado validaciones
  const [validations, setValidations] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    codeCountry: "",
    puesto: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const {
      name,
      lastname,
      email,
      phone,
      codeCountry,
      puesto,
    } = values
    const Blacklist = [
      "@gmail.com",
      "@hotmail.com",
      "@yahoo.com",
      "@outlook.com",
      "yahoo.es",
      "yahoo.com.mx",
      "icloud.com",
      "gmail.co",
      "hotmail.es",
      "hotmail.it",
      "outlook.com",
      "outlook.es",
    ]

    const validations = {
      name: "",
      lastname: "",
      email: "",
      phone: "",
      codeCountry: "",
      puesto: "",
    }
    let isValid = true

    if (!name) {
      validations.name = "Campo requerido"
      isValid = false
    }
    if (name && !/^[a-zA-Z\s]+$/.test(name)) {
      validations.name = "Ingresar solo letras"
      isValid = false
    }
    if (!lastname) {
      validations.lastname = "Campo requerido"
      isValid = false
    }
    if (lastname && !/^[a-zA-Z\s]+$/.test(lastname)) {
      validations.lastname = "Ingresar solo letras"
      isValid = false
    }

    if (!email) {
      validations.email = "Campo requerido"
      isValid = false
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "El formato debe ser el example@mail.com"
      isValid = false
    }
    if (Blacklist) {
      for (let i = 0; i < Blacklist.length; i++)
        if (email.toLowerCase().includes(Blacklist[i]) === true) {
          validations.email = "Ingrese un correo corporativo"
          isValid = false
        }
    }
    if (!phone) {
      validations.phone = "Campo requerido"
      isValid = false
    }
    if (phone && !phone.match(/^[0-9]+$/)) {
      validations.phone = "Ingresar solo numeros"
      isValid = false
    }
    if (codeCountry === "default") {
      validations.codeCountry = "Campo requerido"
      isValid = false
    }
    if (!puesto) {
      validations.puesto = "Campo requerido"
      isValid = false
    }

    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }

    if (
      (name === "name" ||
        name === "lastname") &&
      !/^[a-zA-Z\s]+$/.test(value)
    ) {
      message = "Ingresar solo letras"
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "El formato debe ser el example@mail.com"
    }

    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const { name, lastname, email, phone, puesto } = values

  const {
    name: nameVal,
    lastname: lastnameVal,
    email: emailVal,
    puesto: puestoVal,
    phone: phoneVal,
    codeCountry: codeCountryVal,
  } = validations

  //Funcion envio formulario
  const handleSubmit = async e => {


    e.preventDefault()
    const isValid = validateAll()

    //Validar los campos
    if (isValid) {
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
        setConfirm(true)
      }, 3000)
      try {
        const params = new URLSearchParams()
        params.append("firstname", values.name)
        params.append("lastname", values.lastname)
        params.append("email", values.email)
        params.append("jobtitle", values.puesto)
        params.append("codeCountry", values.codeCountry.code)
        params.append("phone", values.phone)

        await fetch(`${URL_API}/backend/v2/wb/resource/webinar/${landing}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
          body: params,
        })
      } catch (error) {
        console.log(error)
      }
      setValues({
        name: "",
        lastname: "",
        email: "",
      })
    } else {
      console.log("noo entrooo")
      return false
    }
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const [windowsWidth, setWindowsWidth] = useState(350)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 464) {
      setWindowsWidth(270)
    } else {
      setWindowsWidth(350)
    }
  }, [])

  const handleChangeCountry = value => {
    setValues({ ...values, codeCountry: value })
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      width: 150,
      paddingTop: 10,
    }),
    menu: (provided) => ({
      ...provided,
      width: 150,
      marginTop: 0,
    }),
    control: provided => ({
      ...provided,
      width: "100%",
      borderRadius: 8,
      marginTop: 11,
      minHeight: 50,
    }),
  }

  return (
    <section className="container-intro-form-webinar intro-background-hero-inicio " >
      <div className="section-ebook-intro">
        <section className="hero-image-webinar">
          <section className="section-hero-ebook " >
            <h1 className="container-intro-page-casos-de-uso-infoTitle-title">
              {data.title}
            </h1>
            <div className="container-intro-page-casos-de-uso-infoTitle-text">
              {data.description}
            </div>
          </section>
          <div className="container-suscribir-hero-ebook">
            {confirm ? (
              <section className="section-suscribir-ebook">
                <div className="container-heroebook-suscribirone">
                  <div className="confirmation">
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M19.0002 37.9998C14.1319 37.9998 9.26361 36.1492 5.55719 32.4428C-1.85565 25.03 -1.85565 12.9696 5.55719 5.56205C12.97 -1.84553 25.0303 -1.85079 32.4379 5.56205C39.8455 12.9749 39.8508 25.0352 32.4379 32.4428C28.7368 36.1492 23.8685 37.9998 19.0002 37.9998ZM19.0002 3.80085C15.1045 3.80085 11.2141 5.28341 8.24369 8.24855C2.31341 14.1788 2.31341 23.826 8.24369 29.7563C14.174 35.6813 23.8212 35.6866 29.7514 29.7563C35.6817 23.826 35.6817 14.1788 29.7514 8.24855C26.7916 5.28341 22.8959 3.80085 19.0002 3.80085Z"
                          fill="#F3D134"
                        />
                        <path
                          d="M16.7341 25.477C16.2452 25.477 15.7615 25.293 15.3882 24.9197C14.647 24.1785 14.647 22.9745 15.3882 22.2332L25.5296 12.0919C26.2709 11.3506 27.4748 11.3506 28.2161 12.0919C28.9574 12.8331 28.9574 14.0371 28.2161 14.7783L18.0747 24.9197C17.7067 25.293 17.2178 25.477 16.7341 25.477Z"
                          fill="#F3D134"
                        />
                        <path
                          d="M16.7289 25.4771C16.2716 25.4771 15.8142 25.3141 15.4514 24.9829L9.8471 19.8728C9.06901 19.163 9.01644 17.9643 9.72618 17.1863C10.4359 16.4082 11.6346 16.3556 12.4127 17.0601L18.017 22.165C18.7951 22.8747 18.8476 24.0734 18.1379 24.8514C17.7594 25.2668 17.2494 25.4771 16.7289 25.4771Z"
                          fill="#F3D134"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="38" height="38" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <br /> <br />
                    <p className="text-thanks">¡Gracias por registrarte! </p>
                    <br />
                    <p>
                      En la fecha mencionada disfrutarás del mejor contenido creado por Beex y sus partners con el fin de potenciar tu operación omnicanal.
                      <br /> <br />
                      Para sumar, te invitamos a que leas nuestros artículos.  <br /><Link
                        style={{
                          color: "#2C5C82",
                          fontWeight: "600",
                          width: "136px",
                        }}
                        href={"https://beexcc.com/blog/"}
                        type="submit"
                      >
                        Clickeando aquí.
                      </Link>
                    </p>
                  </div>
                </div>
              </section>
            ) : (
              <div style={{ position: "relative" }}>
                <div className="section-suscribir-ebook">
                  <div className="container-heroebook-suscribirtwo">
                    <p className="container-modal-form-whitepaper-title">Regístrate gratis al webinar</p>
                    <div className="inputs-name">
                      <div>
                        <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                          Nombres
                        </p>
                        <input
                          className="input-hero-ebook"
                          placeholder="Ejemplo: María"
                          type="text"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          onBlur={validateOne}
                        />
                        <div className="error">{nameVal}</div>
                      </div>

                      <div>
                        <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                          Apellidos
                        </p>
                        <input
                          className="input-hero-ebook"
                          placeholder="Ejemplo: Timaná Gonzáles"
                          type="text"
                          name="lastname"
                          value={lastname}
                          onChange={handleChange}
                          onBlur={validateOne}
                        />
                        <div className="error">{lastnameVal}</div>
                      </div>
                    </div>

                    <div>
                      <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                        Correo corporativo
                      </p>
                      <input
                        className="input-hero"
                        placeholder="Ejemplo: maria.mora@beex.pe"
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        onBlur={validateOne}
                      />
                      <div className="error">{emailVal}</div>
                    </div>
                    <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                      Teléfono de contacto
                    </p>
                    <div className="code-phone">
                      <div>
                        <Select
                          options={countries}
                          defaultValue={{
                            value: "Perú",
                            label: "Perú",
                            image: "https://flagcdn.com/w320/pe.png",
                            code: "+51",
                          }}
                          onChange={value => handleChangeCountry(value)}
                          placeholder={"Codigo"}
                          styles={customStyles}
                          formatOptionLabel={country => (
                            <div className="country-option">
                              <img src={country.image} alt="country-image" />
                              <span>{country.code}</span>
                            </div>
                          )}
                        />
                        <div className="error">{codeCountryVal}</div>
                      </div>

                      <div>
                        <input
                          className="input-hero"
                          placeholder="Telefono"
                          type="text"
                          name="phone"
                          value={phone}
                          onChange={handleChange}
                          onBlur={validateOne}
                        />
                        <div className="error">{phoneVal}</div>
                      </div>
                    </div>

                    <div style={{ marginTop: "-1px" }}>
                      <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                        Rol en tu empresa
                      </p>
                      <input
                        className="input-hero"
                        placeholder="Jefe de Ventas"
                        type="text"
                        name="puesto"
                        value={puesto}
                        onChange={handleChange}
                        onBlur={validateOne}
                      />
                      <i></i>
                      <div className="error">{puestoVal}</div>
                    </div>


                    <div style={{ marginTop: "31px" }}>
                      <button
                        className="btn-hero-ebook"
                        onClick={handleSubmit}
                        style={{ display: " inline-flex", gap: "12px" }}
                      >
                        Regístrate al webinar
                        {spin ? (
                          <Loader
                            type="Oval"
                            color="#5e5e5e"
                            height={20}
                            width={20}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </section>
  )
}

export default IntroHome
