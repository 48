import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide69/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide69/image/img.png"
import slider1 from "@components/pageGuide/guides/guide69/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide69/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide69/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      6 mejores prácticas de email marketing para inmobiliarias
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      El email marketing es una herramienta útil para atraer y mantener el interés de clientes. Segmenta tu lista de contactos para enviar mensajes específicos según las necesidades de cada grupo. Utiliza un asunto claro que motive la apertura del correo.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const textOne = (
  <p>
Recuerda que, con esta guía, podrás saber como ofrecer contenido relevante relacionado con la compra, venta o renta de propiedades. Asegúrate de incluir llamadas a la acción simples y enlaces a información adicional.
 <br /><br />
 Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Lleva tus campañas de email marketing al siguiente nivel. 
  </p>
)

const data = {
  start: {
    support: "Guía | Inmobiliarias",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Segmenta tu base de datos de manera efectiva.",
      },
      {
        key: 2,
        text: "Aprovecha las interacciones diarias.",
      },
      {
        key: 3,
        text: "Conviértete en un experto en temas inmobiliarios.",
      },
      {
        key: 4,
        text: "Personaliza tus mensajes más allá del nombre.",
      },
      {
        key: 5,
        text: "Crea contenido valioso y relevante.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
