import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide71/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide71/image/img.png"
import slider1 from "@components/pageGuide/guides/guide71/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide71/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide71/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Casos de uso de la IA en la atención al paciente en el sector de salud 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    La inteligencia artificial está transformando la forma en que los pacientes reciben atención en diferentes áreas del sector salud. Se utiliza para analizar datos médicos y apoyar en diagnósticos más precisos. 
   </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const textOne = (
  <p>
Facilita la personalización de tratamientos según las necesidades específicas de cada persona. Permite automatizar tareas administrativas, como la programación de citas y la gestión de historiales médicos. Además, ayuda en el monitoreo remoto de pacientes para un seguimiento constante de su estado. 
<br /><br />
Recuerda que, con esta guía, aprenderás cómo implementar estas herramientas en la práctica médica. Por último, descarga la guía para conocer más casos, ejemplos prácticos y soluciones disponibles. 
</p>
)

const data = {
  start: {
    support: "Guía | Salud",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Automatización de diagnósticos médicos.",
      },
      {
        key: 2,
        text: "Personalización del tratamiento.",
      },
      {
        key: 3,
        text: " Chatbots para atención primaria.",
      },
      {
        key: 4,
        text: " Monitoreo remoto de pacientes.",
      },
      {
        key: 5,
        text: "Optimización de la gestión de citas.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
