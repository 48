/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react"
import { HamburgerSlider } from "react-animated-burgers"
import ticker from "@components/sharedComponents/headerAndFooter/header/icons/iconticker.svg"
import platcon from "@components/sharedComponents/headerAndFooter/header/icons/iconplatcon.svg"
import score from "@components/sharedComponents/headerAndFooter/header/icons/iconscore.svg"
import whatsapp from "@components/sharedComponents/headerAndFooter/header/icons/iconwhatsapp.svg"
import instagram from "@components/sharedComponents/headerAndFooter/header/icons/iconinstagram.svg"
import messenger from "@components/sharedComponents/headerAndFooter/header/icons/iconmesseger.svg"
import webchat from "@components/sharedComponents/headerAndFooter/header/icons/iconwebchat.svg"
import voz from "@components/sharedComponents/headerAndFooter/header/icons/iconvoz.svg"
import chatbots from "@components/sharedComponents/headerAndFooter/header/icons/iconchatbots.svg"
import mensajesmasivos from "@components/sharedComponents/headerAndFooter/header/icons/iconmensajesmasivos.svg"
import mensajesinteracivos from "@components/sharedComponents/headerAndFooter/header/icons/iconmensajesinteractivos.svg"
import whatsappchatbots from "@components/sharedComponents/headerAndFooter/header/icons/iconwhatsappchatbots.svg"
import siptrunksoftware from "@components/sharedComponents/headerAndFooter/header/icons/iconsiptrunksoftware.svg"
import atencion from "@components/sharedComponents/headerAndFooter/header/icons/iconatencion.svg"
import telemarketing from "@components/sharedComponents/headerAndFooter/header/icons/icontelemarketing.svg"
import contactcenter from "@components/sharedComponents/headerAndFooter/header/icons/iconcontactcenter.svg"
import cobranzas from "@components/sharedComponents/headerAndFooter/header/icons/iconcobranzas.svg"
import ecommerce from "@components/sharedComponents/headerAndFooter/header/icons/iconecommerce.svg"
import ebookyguias from "@components/sharedComponents/headerAndFooter/header/icons/iconebookyguias.svg"
import videos from "@components/sharedComponents/headerAndFooter/header/icons/iconvideo.svg"
import videoblogs from "@components/sharedComponents/headerAndFooter/header/icons/iconvideoblog.svg"
import podcast from "@components/sharedComponents/headerAndFooter/header/icons/iconpodcats.svg"
import voiceapi from "@components/sharedComponents/headerAndFooter/header/icons/iconvoiceapi.svg"
import virtualpvx from "@components/sharedComponents/headerAndFooter/header/icons/iconvirtualpvx.svg"
import predictivo from "@components/sharedComponents/headerAndFooter/header/icons/iconpredictivo.svg"
import progresivo from "@components/sharedComponents/headerAndFooter/header/icons/iconprogresivo.svg"
import email from "@components/sharedComponents/headerAndFooter/header/icons/iconemail.svg"
import sms from "@components/sharedComponents/headerAndFooter/header/icons/iconsms.svg"
import gobierno from "@components/sharedComponents/headerAndFooter/header/icons/icongobierno.svg"
import educacion from "@components/sharedComponents/headerAndFooter/header/icons/iconeducacion.svg"
import salud from "@components/sharedComponents/headerAndFooter/header/icons/iconsalud.svg"
import concesionaria from "@components/sharedComponents/headerAndFooter/header/icons/iconconcesionaria.svg"
import logistica from "@components/sharedComponents/headerAndFooter/header/icons/iconlogistica.svg"
import webinar from "@components/sharedComponents/headerAndFooter/header/icons/iconwebinar.svg"

import { Link } from "gatsby"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"

//variable de entorno
const GATSBY_ROOT_URL = process.env.GATSBY_ROOT_URL

const useStyles = makeStyles(theme => ({
  menuItemSelected: {
    borderBottom: "4px solid #f5d312 !important",
  },
  navbarFixed: {
    background: "white",
    position: "fixed",
    transition: "all 0.3s ease-out",
    zIndex: "1000",
  },
  textcolorheader: {
    color: "black",
    "&:hover": {
      color: "black",
    },
  },
  textcolorheaderTwo: {
    color: "black",
    "&:hover": {
      color: "black",
    },
  },
  btnheadertop: {
    color: "#5831D8",
    border: "1px solid #5831D8",
  },
  btnheaderscroll: {
    color: "#5831D8",
    border: "1px solid #5831D8",
  },
  logo: {
    backgroundImage: `url(${GATSBY_ROOT_URL}/LogoNegro.svg)`,
    height: "80px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",

    "&:hover": {
      backgroundImage: `url(${GATSBY_ROOT_URL}/LogoNegro.svg)`,
    },
  },
  logotwo: {
    backgroundImage: `url(${GATSBY_ROOT_URL}/LogoNegro.svg)`,
    height: "80px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  root: {
    background: "white",
    position: "absolute",
    transition: "all 0.3s ease-out",
    zIndex: "1000",
    "&:hover": {
      background: "#ffffff",
      "& $textcolorheaderTwo": {
        color: "black",
      },
      "& $btnheaderscroll": {
        color: "#5831D8",
        border: "1px solid #5831D8",
      },
      "& $logo": {
        backgroundImage: `url(${GATSBY_ROOT_URL}/LogoNegro.svg)`,
      },
    },
  },
}))

//Rotacion flecha de Submenu
const IconButtonWrapper = styled.div`
  float: right;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  -webkit-transform: ${props =>
    props.rotate === "true" ? `rotate(90deg)` : ""};
`
//Hover borde menu al seleccionar el submenu
const style = {
  hover: {
    borderBottom: "4px solid #f5d312",
  },
}

//Boton Hamburguesa
const Header = props => {
  const classes = useStyles(props)
  const [posScroll, setposScroll] = useState(0)

  const handleScroll = () => {
    let lastScrollY = window.scrollY
    setposScroll(lastScrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [posScroll])

  const [activateHamburger, setActivateHamburger] = useState(false)
  const [btnHamburger, setBtnHamburger] = useState(false)
  const drawerToggleClickHandler = event => {
    event.preventDefault()
    setActivateHamburger(!activateHamburger)
    setBtnHamburger(!btnHamburger)
  }

  //Desplegar submenu de productos
  const [activateMenuProduct, setActivateMenuProduct] = useState(false)
  const [activateSubMenuProduct, setActivateSubMenuProduct] = useState(false)
  const clickProducts = event => {
    event.preventDefault()
    setActivateMenuProduct(!activateMenuProduct)
    setActivateSubMenuProduct(!activateSubMenuProduct)
    //Cerrar submenuSoluciones
    setActivateMenuSolutions(false)
    setActivateSubMenuSolutions(false)
    setActivateMenuRecurses(false)
    setActivateSubMenuRecurses(false)
  }

  //Desplegar submenu de soluciones
  const [activateMenuSolutions, setActivateMenuSolutions] = useState(false)
  const [activateSubMenuSolutions, setActivateSubMenuSolutions] = useState(
    false
  )
  const clickSolutions = event => {
    event.preventDefault()
    setActivateMenuSolutions(!activateMenuSolutions)
    setActivateSubMenuSolutions(!activateSubMenuSolutions)
    //Cerrar submenuProductos
    setActivateMenuProduct(false)
    setActivateSubMenuProduct(false)
    setActivateMenuRecurses(false)
    setActivateSubMenuRecurses(false)
  }

  //Desplegar submenu de recursos
  const [activateMenuRecurses, setActivateMenuRecurses] = useState(false)
  const [activateSubMenuRecurses, setActivateSubMenuRecurses] = useState(false)
  const clickRecurses = event => {
    event.preventDefault()
    setActivateMenuRecurses(!activateMenuRecurses)
    setActivateSubMenuRecurses(!activateSubMenuRecurses)
    //Cerrar submenuProductos
    setActivateMenuProduct(false)
    setActivateSubMenuProduct(false)
    setActivateMenuSolutions(false)
    setActivateSubMenuSolutions(false)
  }

  const clickCollapseMenu = () => {
    setActivateHamburger(false)
    setBtnHamburger(false)
  }
  //Estado para activar hover de menu productos
  const [hoverProducts, setHoverProducts] = useState(false)
  //Estado para activar hover de menu soluciones
  const [hoverSolutions, setHoverSolutions] = useState(false)

  //Estado para activar hover de menu recursos
  const [hoverRecurses, setHoverRecurses] = useState(false)

  return (
    <div className="section-header">
      <header
        className={`header ${posScroll > 1
          ? props.windowsWidth
            ? classes.navbarFixed
            : classes.navbarFixed
          : classes.root
          }`}
      >
        <div className="grid-header">
          <Link to={`/${props.location.search}`} className="logo" aria-label="logo">
            <div
              className={posScroll > 1 ? classes.logotwo : classes.logo}
            ></div>
          </Link>
          <br />
          {/* <!-- Menu Desktop--> */}
          <div id="mainmenu">
            <ul className="navbar ">
              <li className="nav-links">
                <Link
                  name="text"
                  className={`nav-link ${posScroll > 1
                    ? classes.textcolorheader
                    : classes.textcolorheaderTwo
                    } ${`/` === props.path ? classes.menuItemSelected : ""}`}
                  to={`/${props.location.search}`}
                >
                  Inicio
                </Link>
              </li>
              <li className="nav-links has-sub hover-barra-menu-product">
                <a
                  href={() => false}
                  name="text"
                  className={`nav-link ${posScroll > 1
                    ? classes.textcolorheader
                    : classes.textcolorheaderTwo
                    } ${`/productos/` === props.path
                      ? classes.menuItemSelected
                      : ""
                    }`}
                  style={{
                    ...style.normal,
                    ...(hoverProducts ? style.hover : null),
                  }}
                  type="submit"
                >
                  Productos
                </a>
                {/* eslint-disable-next-line*/}
                <ul
                  className="sub-menu-products"
                  onMouseEnter={() => {
                    setHoverProducts(true)
                  }}
                  onMouseLeave={() => {
                    setHoverProducts(false)
                  }}
                >
                  <ul className="sub-menu-todos-productos">
                    <li className="nav-productos container-title-sub-menu-todos-productos ">
                      <p className="title-productos-and-text title-categorias-menu">
                        Productos
                      </p>
                      <hr />
                    </li>

                    <li className="items-productos-nav nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/contact-center/${props.location.search}`}
                      >
                        <img loading="lazy" src={score} alt="Contact Center" />
                        <div className="text-navproducts">
                          <p className="title-productos-and-text hovernav">
                            Contact Center
                          </p>
                          <p className="title-productos-and-text intro-product">
                            Software en la nube para Contact Centers
                          </p>
                        </div>
                      </Link>
                    </li>
                    <li className="items-productos-nav nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/conversations/${props.location.search}`}
                      >
                        <img loading="lazy" src={ticker} alt="logo" />
                        <div className="text-navproducts">
                          <p className="title-productos-and-text hovernav">
                            Conversations
                          </p>
                          <p className="title-productos-and-text intro-product">
                            Atención al cliente omnicanal y chatbots
                          </p>
                        </div>
                      </Link>
                    </li>
                    <li className="items-productos-nav nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/validation/${props.location.search}`}
                      >
                        <img loading="lazy" src={platcon} alt="Validation" />
                        <div className="text-navproducts">
                          <p className="title-productos-and-text hovernav">
                            Validation
                          </p>
                          <p className="title-productos-and-text intro-product">
                            Plataforma de Contactabilidad en la nube
                          </p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                  <ul className="sub-menu-canales">
                    <li className="nav-productos">
                      <p className="title-categorias-menu">Canales</p>
                      <hr />
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/whatsapp-business-api/${props.location.search}`}
                      >
                        <img loading="lazy" src={whatsapp} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">WhatsApp</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/software-voip/${props.location.search}`}
                      >
                        <img loading="lazy" src={voz} alt="Validation" />
                        <div className="text-navproducts">
                          <p className="hovernav">Voz</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/email/${props.location.search}`}
                      >
                        <img loading="lazy" src={email} alt="Validation" />
                        <div className="text-navproducts">
                          <p className="hovernav">Correo Electrónico</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/sms/${props.location.search}`}
                      >
                        <img loading="lazy" src={sms} alt="Validation" />
                        <div className="text-navproducts">
                          <p className="hovernav">SMS</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/chatbot-instagram/${props.location.search}`}
                      >
                        <img loading="lazy" src={instagram} alt="Validation" />
                        <div className="text-navproducts">
                          <p className="hovernav">Instagram</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/chat-en-vivo-web/${props.location.search}`}
                      >
                        <img loading="lazy" src={webchat} alt="Validation" />
                        <div className="text-navproducts">
                          <p className="hovernav">Webchat</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/conversations/${props.location.search}`}
                      >
                        <img loading="lazy" src={messenger} alt="messenger" />
                        <div className="text-navproducts">
                          <p className="hovernav">Messenger</p>
                        </div>
                      </Link>
                    </li>



                  </ul>

                  <ul className="sub-menu-funcionalidades">
                    <li
                      className="nav-productos"
                      style={{ marginBottom: "0px !important" }}
                    >
                      <p className="title-categorias-menu"> Funcionalidades</p>
                      <hr />
                    </li>
                    <li className="sub-title-menu-funcionalidades-nav nav-productos">
                      <p className="title-blue-nav">Mensajería</p>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/chatbots-online/${props.location.search}`}
                      >
                        <img loading="lazy" src={chatbots} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Chatbots</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/whatsapp-business-api/chatbots-precio/${props.location.search}`}
                      >
                        <img
                          loading="lazy"
                          src={whatsappchatbots}
                          alt="Validation"
                        />
                        <div className="text-navproducts">
                          <p className="hovernav">WhatsApp Chatbots</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/whatsapp-business-api/mensajes-masivos/${props.location.search}`}
                      >
                        <img
                          loading="lazy"
                          src={mensajesmasivos}
                          alt="Validation"
                        />
                        <div className="text-navproducts">
                          <p className="hovernav">WhatsApp: Envíos masivos</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/whatsapp-business-api/mensajes-interactivos/${props.location.search}`}
                      >
                        <img
                          loading="lazy"
                          src={mensajesinteracivos}
                          alt="mensajes"
                        />
                        <div className="text-navproducts">
                          <p className="hovernav">
                            WhatsApp: Mensajes interactivos
                          </p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                  <ul className="sub-menu-funcionalidades sub-menu-funcionalidadestwo">
                    <li
                      className="nav-productos"
                      style={{ marginBottom: "0px !important" }}
                    >
                      <p
                        className="title-categorias-menu"
                        style={{ color: "transparent" }}
                      >
                        {" "}
                        Funcionalidades
                      </p>
                      <hr />
                    </li>
                    <li className="sub-title-menu-funcionalidades-nav nav-productos">
                      <p className="title-blue-nav">Voz</p>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/sip-trunk-software/${props.location.search}`}
                      >
                        <img
                          loading="lazy"
                          src={siptrunksoftware}
                          alt="Validation"
                        />
                        <div className="text-navproducts">
                          <p className="hovernav">SIP Trunk</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/voice-api/${props.location.search}`}
                      >
                        <img loading="lazy" src={voiceapi} alt="voice" />
                        <div className="text-navproducts">
                          <p className="hovernav">Voice API</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/virtual-pbx/${props.location.search}`}
                      >
                        <img loading="lazy" src={virtualpvx} alt="virtual" />
                        <div className="text-navproducts">
                          <p className="hovernav">Virtual PBX</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/marcador-progresivo/${props.location.search}`}
                      >
                        <img loading="lazy" src={progresivo} alt="marcador" />
                        <div className="text-navproducts">
                          <p className="hovernav">Marcador Progresivo</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-productos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/marcador-predictivo/${props.location.search}`}
                      >
                        <img loading="lazy" src={predictivo} alt="marcador" />
                        <div className="text-navproducts">
                          <p className="hovernav">Marcador Predictivo</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </ul>
              </li>

              <li className="nav-links has-subm">
                <a
                  href={() => false}
                  className={`nav-link ${posScroll > 1
                    ? classes.textcolorheader
                    : classes.textcolorheaderTwo
                    } ${`/soluciones/` === props.path
                      ? classes.menuItemSelected
                      : ""
                    }`}
                  style={{
                    ...style.normal,
                    ...(hoverSolutions ? style.hover : null),
                  }}
                  type="submit"
                >
                  Soluciones
                </a>
                {/* eslint-disable-next-line */}
                <ul
                  className="sub-menu-solutions"
                  onMouseEnter={() => {
                    setHoverSolutions(true)
                  }}
                  onMouseLeave={() => {
                    setHoverSolutions(false)
                  }}
                >
                  <ul>
                    <li className="nav-solutions" style={{ marginRight: '20px' }}>
                      <p className="title-categorias-menu">Casos de uso</p>
                      <hr />
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/atencion-al-cliente/${props.location.search}`}
                      >
                        <img loading="lazy" src={atencion} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav"> Atención al Cliente</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/marketing-ventas/${props.location.search}`}
                      >
                        <img loading="lazy" src={telemarketing} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Marketing y ventas</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/software-cobranzas/${props.location.search}`}
                      >
                        <img loading="lazy" src={cobranzas} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Cobros y Pagos</p>
                        </div>
                      </Link>
                    </li>


                  </ul>
                  <ul>
                    <li className="nav-solutions" style={{ marginRight: '20px' }}>
                      <p className="title-categorias-menu">Industrias</p>
                      <hr />
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/industria/banca-seguro-finanzas/${props.location.search}`}
                      >
                        <img loading="lazy" src={gobierno} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Banca, Seguros y Finanzas</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/solucion-contact-center/${props.location.search}`}
                      >
                        <img loading="lazy" src={contactcenter} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Contact Center</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/solucion-omnicanal-ecommerce/${props.location.search}`}
                      >
                        <img loading="lazy" src={ecommerce} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Ecommerce</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/industria/educacion/${props.location.search}`}
                      >
                        <img loading="lazy" src={educacion} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Educación</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/industria/salud/${props.location.search}`}
                      >
                        <img loading="lazy" src={salud} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Salud</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/industria/concesionarias/${props.location.search}`}
                      >
                        <img loading="lazy" src={concesionaria} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Concesionarias</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-solutions">
                      <Link
                        className="nav-link-submenu solutions"
                        to={`/industria/logistica/${props.location.search}`}
                      >
                        <img loading="lazy" src={logistica} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Logística</p>
                        </div>
                      </Link>
                    </li>
                  </ul>

                </ul>

              </li>

              <li className="nav-links">
                <Link
                  className={`nav-link ${posScroll > 1
                    ? classes.textcolorheader
                    : classes.textcolorheaderTwo
                    } ${`/casos-de-exito/` === props.path
                      ? classes.menuItemSelected
                      : ""
                    }`}
                  to={`/casos-de-exito/${props.location.search}`}
                >
                  Casos de Éxito
                </Link>
              </li>

              <li className="nav-links has-sub">
                <a
                  href={() => false}
                  name="text"
                  className={`nav-link ${posScroll > 1
                    ? classes.textcolorheader
                    : classes.textcolorheaderTwo
                    } ${`/recursos/` === props.path ? classes.menuItemSelected : ""
                    }`}
                  style={{
                    ...style.normal,
                    ...(hoverRecurses ? style.hover : null),
                  }}
                  type="submit"
                >
                  Recursos
                </a>
                {/* eslint-disable-next-line*/}
                <ul
                  className="sub-menu-recursos"
                  onMouseEnter={() => {
                    setHoverRecurses(true)
                  }}
                  onMouseLeave={() => {
                    setHoverRecurses(false)
                  }}
                >
                  <ul>
                    <li className="nav-productos" >
                      <p className="title-categorias-menu">Aprendizaje</p>
                      <hr />
                    </li>

                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/recursos/${props.location.search}`}
                      >
                        <img loading="lazy" src={ebookyguias} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Descargables</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/videos/${props.location.search}`}
                      >
                        <img loading="lazy" src={videos} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Videos</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/videoblog/${props.location.search}`}
                      >
                        <img loading="lazy" src={videoblogs} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Videoblogs</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/webinar/${props.location.search}`}
                      >
                        <img loading="lazy" src={webinar} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Webinars</p>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`/podcast/${props.location.search}`}
                      >
                        <img loading="lazy" src={podcast} alt="logo" />
                        <div className="text-navproducts">
                          <p className="hovernav">Podcast</p>
                        </div>
                      </Link>
                    </li>
                  
                  </ul>
                  <ul className="sub-menu-blog">
                    <li className="nav-productos">
                      <p
                        className="title-categorias-menu"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        BLOG{" "}
                        <a
                          href="https://beexcc.com/blog/"
                          className="link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Ver todos
                          <span className="flecha">
                            <svg
                              width="16"
                              height="10"
                              viewBox="0 0 16 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 5H15M15 5L10.9091 1M15 5L10.9091 9"
                                stroke="#2C5C82"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </a>
                      </p>
                      <hr />
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`https://beexcc.com/blog/noticias-atencion-al-cliente/`}
                      >
                        Atención al cliente
                      </Link>
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`https://beexcc.com/blog/whatsapp-business-ecommerce/`}
                      >
                        WhatsApp
                      </Link>
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`https://beexcc.com/blog/noticias-contact-center/`}
                      >
                        Contact Center
                      </Link>
                    </li>
                    <li className="nav-recursos">
                      <Link
                        className="nav-link-submenu productos"
                        to={`https://beexcc.com/blog/telemarketing/`}
                      >
                        Telemarketing
                      </Link>
                    </li>
                  </ul>
                </ul>
              </li>

              <li className="nav-links">
                <Link
                  className={`nav-link ${posScroll > 1
                    ? classes.textcolorheader
                    : classes.textcolorheaderTwo
                    } ${`/programa-partners-beex/` === props.path
                      ? classes.menuItemSelected
                      : ""
                    }`}
                  to={`/programa-partners-beex/${props.location.search}`}
                >
                  Partner
                </Link>
              </li>
            </ul>
          </div>
          <div>
            {/* eslint-disable-next-line */}
            <div
              type="button"
              className="toggle-button"
              onClick={event => {
                drawerToggleClickHandler(event)
              }}
            >
              <HamburgerSlider
                barColor={"black"}
                buttonStyle={{ outline: "none" }}
                isActive={btnHamburger}
              />
            </div>
          </div>
          <a
            id="btn-transparente"
            className={`btn-nav ${posScroll > 1 ? classes.btnheadertop : classes.btnheaderscroll
              }`}
            href={`/hablemos/${props.location.search}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Hablemos
            <img
              loading="lazy"
              src="https://px.ads.linkedin.com/collect/?pid=2384313&conversionId=3897129&fmt=gif"
              style={{ display: "none", height: 1, width: 1 }}
              alt=""
            />
          </a>

          {/* <!-- Menu Mobile--> */}
          {activateHamburger ? (
            <div
              className={
                props.bannerCTA ? "navigation-banner" : "navigation-sin-banner"
              }
            >
              <nav className="navigation">
                <ul className="mainmenu">
                  <li className="margin-list">
                    <Link
                      onClick={event => {
                        clickCollapseMenu(event)
                      }}
                      to={`/${props.location.search}`}
                    >
                      Inicio
                    </Link>
                  </li>
                  <li className="margin-list">
                    <button
                      className="btn-subproducts"
                      onClick={event => {
                        clickProducts(event)
                      }}
                      style={{
                        color: activateSubMenuProduct ? "#FAAD14" : "#262A31",
                      }}
                    >
                      Productos
                      <div className="iconsubmenu">
                        <IconButtonWrapper
                          rotate={activateMenuProduct.toString()}
                        >
                          <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 11L6 6L1 1"
                              stroke={
                                activateSubMenuProduct ? "#FAAD14" : "#262A31"
                              }
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButtonWrapper>
                      </div>
                    </button>
                    {activateSubMenuProduct ? (
                      <ul className="submenu">
                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-categorias-menu">Productos</p>
                        </li>

                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/contact-center/${props.location.search}`}
                          >
                            <img loading="lazy" src={score} alt="Score " />
                            <div className="text-navproducts">
                              <p style={{ color: "#151E28", fontSize: "14px" }}>
                                Contact Center
                              </p>
                              <p className="intro-product">
                                Software en la nube para Contact Centers
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/conversations/${props.location.search}`}
                          >
                            <img loading="lazy" src={ticker} alt="logo" />
                            <div className="text-navproducts">
                              <p style={{ color: "#151E28", fontSize: "14px" }}>
                                Conversations
                              </p>
                              <p className="intro-product">
                                Atención al cliente omnicanal y chatbots
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/validation/${props.location.search}`}
                          >
                            <img loading="lazy" src={platcon} alt="logo" />
                            <div className="text-navproducts">
                              <p style={{ color: "#151E28", fontSize: "14px" }}>
                                Validation
                              </p>
                              <p className="intro-product">
                                Plataforma de Contactabilidad en la nube
                              </p>
                            </div>
                          </Link>
                        </li>
                        <hr />

                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-categorias-menu">Canales</p>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/whatsapp-business-api/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={whatsapp} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">WhatsApp</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/software-voip/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={voz} alt="Validation" />
                            <div className="text-navproducts">
                              <p className="hovernav">Voz</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/email/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={email} alt="Validation" />
                            <div className="text-navproducts">
                              <p className="hovernav">Correo Electrónico</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/sms/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={sms} alt="Validation" />
                            <div className="text-navproducts">
                              <p className="hovernav">SMS</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/chatbot-instagram/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={instagram} alt="Validation" />
                            <div className="text-navproducts">
                              <p className="hovernav">Instagram</p>
                            </div>
                          </Link>
                        </li>

                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/chat-en-vivo-web/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={webchat} alt="Validation" />
                            <div className="text-navproducts">
                              <p className="hovernav">Webchat</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/chatbots-online/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={messenger} alt="messenger" />
                            <div className="text-navproducts">
                              <p className="hovernav">Messenger</p>
                            </div>
                          </Link>
                        </li>
                        <hr />

                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-categorias-menu">
                            Funcionalidades
                          </p>
                        </li>
                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-blue-nav">Mensajería</p>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/chatbots-online/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={chatbots} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Chatbots</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/whatsapp-business-api/chatbots-precio/${props.location.search}`}
                          >
                            <img
                              loading="lazy"
                              src={whatsappchatbots}
                              alt="Validation"
                            />
                            <div className="text-navproducts">
                              <p className="hovernav">WhatsApp Chatbots</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/whatsapp-business-api/mensajes-interactivos/${props.location.search}`}
                          >
                            <img
                              loading="lazy"
                              src={mensajesinteracivos}
                              alt="mensajes"
                            />
                            <div className="text-navproducts">
                              <p className="hovernav">
                                WhatsApp: Mensajes interactivos
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/whatsapp-business-api/mensajes-masivos/${props.location.search}`}
                          >
                            <img
                              loading="lazy"
                              src={mensajesmasivos}
                              alt="Validation"
                            />
                            <div className="text-navproducts">
                              <p className="hovernav">
                                WhatsApp: Envíos masivos
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-blue-nav">Voz</p>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/sip-trunk-software/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={chatbots} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">SIP Trunk</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/voice-api/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={voiceapi} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Voice API</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/virtual-pbx/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={virtualpvx} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Virtual PBX</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/marcador-progresivo/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={progresivo} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Marcador Progresivo</p>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-productos margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`/marcador-predictivo/${props.location.search}`}
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                          >
                            <img loading="lazy" src={predictivo} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Marcador Predictivo</p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      false
                    )}
                  </li>
                  <li className="margin-list">
                    <button
                      className="btn-subproducts"
                      onClick={event => {
                        clickSolutions(event)
                      }}
                      style={{
                        color: activateSubMenuSolutions ? "#FAAD14" : "#262A31",
                      }}
                    >
                      Soluciones
                      <div className="iconsubmenu">
                        <IconButtonWrapper
                          rotate={activateMenuSolutions.toString()}
                        >
                          <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 11L6 6L1 1"
                              stroke={
                                activateSubMenuSolutions ? "#FAAD14" : "#262A31"
                              }
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButtonWrapper>
                      </div>
                    </button>

                    {activateSubMenuSolutions ? (
                      <ul className="submenu">
                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-categorias-menu">Casos de uso</p>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu solutions"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/atencion-al-cliente/${props.location.search}`}
                          >
                            <img loading="lazy" src={atencion} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav"> Atención al Cliente</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu solutions"
                            to={`/marketing-ventas/${props.location.search}`}
                          >
                            <img
                              loading="lazy"
                              src={telemarketing}
                              alt="logo"
                            />
                            <div className="text-navproducts">
                              <p className="hovernav">Marketing y ventas</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu solutions"
                            to={`/software-cobranzas/${props.location.search}`}
                          >
                            <img loading="lazy" src={cobranzas} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Cobros y Pagos</p>
                            </div>
                          </Link>
                        </li>

                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-categorias-menu">Industrias</p>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu solutions"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/industria/banca-seguro-finanzas/${props.location.search}`}
                          >
                            <img loading="lazy" src={gobierno} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Banca, Seguros y Finanzas</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu solutions"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/solucion-contact-center/${props.location.search}`}
                          >
                            <img loading="lazy" src={contactcenter} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Contact Center</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu solutions"
                            to={`/solucion-omnicanal-ecommerce/${props.location.search}`}
                          >
                            <img loading="lazy" src={ecommerce} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Ecommerce</p>
                            </div>
                          </Link>
                        </li>

                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu solutions"
                            to={`/industria/educacion/${props.location.search}`}
                          >
                            <img
                              loading="lazy"
                              src={educacion}
                              alt="logo"
                            />
                            <div className="text-navproducts">
                              <p className="hovernav">Educación</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu solutions"
                            to={`/industria/salud/${props.location.search}`}
                          >
                            <img loading="lazy" src={salud} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Salud</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu solutions"
                            to={`/industria/concesionarias/${props.location.search}`}
                          >
                            <img loading="lazy" src={concesionaria} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Concesionarias</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu solutions"
                            to={`/industria/logistica/${props.location.search}`}
                          >
                            <img loading="lazy" src={logistica} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Logística</p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      false
                    )}
                  </li>
                  <li className="margin-list">
                    <Link
                      onClick={event => {
                        clickCollapseMenu(event)
                      }}
                      to={`/casos-de-exito/${props.location.search}`}
                    >
                      Casos de Éxito
                    </Link>
                  </li>
                  <li className="margin-list">
                    <button
                      className="btn-subproducts"
                      onClick={event => {
                        clickRecurses(event)
                      }}
                      style={{
                        color: activateSubMenuRecurses ? "#FAAD14" : "#262A31",
                      }}
                    >
                      Recursos
                      <div className="iconsubmenu">
                        <IconButtonWrapper
                          rotate={activateMenuRecurses.toString()}
                        >
                          <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 11L6 6L1 1"
                              stroke={
                                activateSubMenuRecurses ? "#FAAD14" : "#262A31"
                              }
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButtonWrapper>
                      </div>
                    </button>

                    {activateSubMenuRecurses ? (
                      <ul className="submenu">
                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p className="title-categorias-menu">Aprendizaje</p>
                        </li>

                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            to={`/recursos/${props.location.search}`}
                          >
                            <img loading="lazy" src={ebookyguias} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Descargables</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu productos"
                            to={`/videos/${props.location.search}`}
                          >
                            <img loading="lazy" src={videos} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Videos</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu productos"
                            to={`/videoblog/${props.location.search}`}
                          >
                            <img loading="lazy" src={videoblogs} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Videoblogs</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu productos"
                            to={`/webinar/${props.location.search}`}
                          >
                            <img loading="lazy" src={webinar} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Webinars</p>
                            </div>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            onClick={event => {
                              clickCollapseMenu(event)
                            }}
                            className="nav-link-submenu productos"
                            to={`/podcast/${props.location.search}`}
                          >
                            <img loading="lazy" src={podcast} alt="logo" />
                            <div className="text-navproducts">
                              <p className="hovernav">Podcast</p>
                            </div>
                          </Link>
                        </li>
                       
                        <hr />

                        <li className="nav-productos container-title-sub-menu-todos-productos ">
                          <p
                            className="title-categorias-menu"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingRight: "20px",
                            }}
                          >
                            BLOG{" "}
                            <a
                              href="https://beexcc.com/blog/"
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver todos
                              <span className="flecha">
                                <svg
                                  width="16"
                                  height="10"
                                  viewBox="0 0 16 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 5H15M15 5L10.9091 1M15 5L10.9091 9"
                                    stroke="#2C5C82"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </a>
                          </p>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`https://beexcc.com/blog/noticias-atencion-al-cliente/`}
                          >
                            <p className="hovernav">Atención al cliente</p>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`https://beexcc.com/blog/whatsapp-business-ecommerce/`}
                          >
                            <p className="hovernav">WhatsApp</p>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`https://beexcc.com/blog/noticias-contact-center/`}
                          >
                            <p className="hovernav">Contact Center</p>
                          </Link>
                        </li>
                        <li className="margin-sublist">
                          <Link
                            className="nav-link-submenu productos"
                            to={`https://beexcc.com/blog/telemarketing/`}
                          >
                            <p className="hovernav">Telemarketing</p>
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      false
                    )}
                  </li>
                  <li className="margin-list">
                    <Link
                      onClick={event => {
                        clickCollapseMenu(event)
                      }}
                      to={`/programa-partners-beex/${props.location.search}`}
                    >
                      Partner
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          ) : (
            false
          )}
        </div>
      </header>
    </div>
  )
}
export default Header
