/* eslint-disable no-undef */
import React, { useState } from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import Start from "@components/pageFunctionalities/pageAlternativaWhatsapp/components/start"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import SilderPricing from "@components/pageThanks/pageThanksTicker/components/silderPricing/silderPricing"
import UseScore from "@components/pageCasosdeUso/pageAlternativaMarketing/components/useCards"
import TitleUnderlineTestimony from "@components/pageChannels/pageWhatsapp/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import gobierno from "@components/sharedComponents/testimonies/images/iconos/gobierno.svg"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Compare from "@components/pageFunctionalities/pageTwiliovsBeex/components/compare"
import data from "@components/pageFunctionalities/pageTwiliovsBeex/data"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"


const StructurePageMarketing = ({ location }) => {
  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas

      case 1:
        return concesionarias

      case 2:
        return salud
      case 3:
        return logistica
      case 4:
        return gobierno
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Concesionarias"
      case 2:
        return "Salud"
      case 3:
        return "Logística"
      case 4:
        return "Gobierno"
      default:
        return "Conoce otros casos de éxito"
    }
  }
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleTestimony = <p> Ayudamos a  <TitleUnderlineTestimony underline="diferentes" />  <TitleUnderlineTestimony underline="sectores" /> a fortalecer la relación con sus clientes por WhatsApp
  </p>
  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }


  return (
    <>
      <div className="fnd">
        <BannerCookies />
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/V32jj-f-_h8?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}
        <div className="container">
          <Start data={data.start} location={location} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Únete a las empresas que confían en nosotros" />
          <Compare data={data.compare} />
          <BannerCTA
            title="¡Crea tu chatbot en WhatsApp!"
            text="Un experto te ayudará a crear tu flujo automatizado en minutos."
            link="/hablemos/"
            textButton="Contacta con nosotros"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <UseScore data={data.useScore} />
          <SilderPricing data={data.pricing} location={location} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <BannerContact data={data.contacts} location={location} link={true} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </div>
    </>
  )
}

export default StructurePageMarketing
