import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="368" height="10" viewBox="0 0 368 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M68.2424 9.2411C60.6568 8.28597 5.08582 9.12599 1.04784 8.83195C-2.24827 8.44516 3.04466 1.85754 6.10888 0.895543C7.18297 0.54963 8.42954 0.503185 11.2847 0.672233C15.8117 0.987073 74.363 0.602645 74.363 0.602645C75.1353 0.808841 77.457 0.130347 77.8821 0.379825C79.2212 0.963166 174.124 0.510101 182.218 0.506911C187.124 0.505176 183.242 0.885671 189.017 0.74712C201.324 0.472695 228.144 0.872677 237.47 0.543207C244.934 0.27074 246.543 0.26372 253.705 0.530646C261.025 0.772697 299.948 0.598685 304.399 0.150255C305.488 0.0269362 306.324 0.074403 306.324 0.220643C306.324 0.252434 333.76 0.457478 333.886 0.572373C334.09 0.719338 334.926 0.792229 335.7 0.693228C337.136 0.545704 366.341 0.0957344 366.797 0.523355C368.229 1.74922 364.187 6.93606 360.791 8.18936C357.315 9.53141 338.262 7.94444 322.939 8.70417L251.61 8.53476L236.32 8.98939C234.57 8.54448 227.83 9.62701 223.762 8.8242C222.043 8.50657 209.895 8.41912 207.212 8.74027C204.876 8.98635 142.731 8.12455 136.354 8.98583C124.997 8.20812 78.9242 9.10081 68.2424 9.2411Z" fill="#FFA700" />
    </svg>
  </span>
)
