import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.png"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.png"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.png"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageFourMobile from "./images/imagesMobile/imageThree.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Integra chatbots para consultas frecuentes",
      description: "Según SEMrush, entre 1k y 880 usuarios buscan en Google &quotresultados de laboratorio de la Clínica Ricardo Palma &quot cada mes. Esto refleja que los pacientes necesitan respuestas sobre sus pruebas médicas, lo que puede generar un cuello de botella en tu equipo al ser una consulta recurrente.<br /><br /> Con Beex, puedes integrar la API de WhatsApp con tus herramientas internas para crear un chatbot automatizado. Este bot permitirá a los pacientes obtener sus resultados de manera rápida simplemente proporcionando datos básicos. Así, reduces la carga operativa de tus agentes.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Aumenta las adquisiciones de pacientes al PlanSalud",
      description: "Ayuda a tus agentes a convertir más leads utilizando la tecnología de Beex. Diseña workflows automatizados para enviar mensajes en momentos clave, como después de que un usuario deja sus datos o tras una primera reunión informativa. <br /><br /> Con Beex, puedes realizar un seguimiento automatizado de los leads interesados que no han respondido, asegurando que no se pierda ninguna oportunidad de conversión. Todo este proceso se gestiona desde un solo lugar.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Comunica eventos y anuncios por WhatsApp y correo",
      description: "Impulsa el alcance de eventos clave, como consultorios en vivo o concursos de fotografía, utilizando canales de alto impacto como WhatsApp y correo electrónico. Automatiza estas comunicaciones para enviar mensajes masivos a los usuarios registrados en tu base de datos. <br /><br /> Además de agilizar el trabajo del equipo interno de marketing y reduce costos operativos, reemplazando tácticas tradicionales como PR o campañas en medios digitales para la divulgación de estos eventos.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-pandero">
      <p className="container-experience-pandero-title">
        <TitleUnderlineOne underline="    Automatiza y" /> <TitleUnderlineTwo underline="optimiza" /> todos los procesos de atención al paciente
      </p>


      <div className="container-experience-pandero-cardsOne">
        <div>
          <section className="container-experience-pandero-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 6px 32px" }}>
            <section style={{ padding: "0px 26px" }}>
              <p className="container-experience-pandero-cardsOne-card-title">Integra chatbots para consultas frecuentes </p>
              <p className="container-experience-pandero-cardsOne-card-description">
                Según SEMrush, entre 1k y 880 usuarios buscan en Google "resultados de laboratorio de la Clínica Ricardo Palma" cada mes. Esto refleja que los pacientes necesitan respuestas sobre sus pruebas médicas, lo que puede generar un cuello de botella en tu equipo al ser una consulta recurrente.
                <br /><br />
                Con Beex, puedes integrar la API de WhatsApp con tus herramientas internas para crear un chatbot automatizado. Este bot permitirá a los pacientes obtener sus resultados de manera rápida simplemente proporcionando datos básicos. Así, reduces la carga operativa de tus agentes.
              </p>
            </section>
            <section>
              <img
                className="container-experience-pandero-cardsOne-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        <div className="container-experience-pandero-cardsTwo">
          <div className="container-experience-pandero-cardsOne-column">
            <section className="container-experience-pandero-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
              <section>
                <p className="container-experience-pandero-cardsOne-card-title">Aumenta las adquisiciones de pacientes al PlanSalud</p>
                <p className="container-experience-pandero-cardsOne-card-description">
                  Ayuda a tus agentes a convertir más leads utilizando la tecnología de Beex. Diseña workflows automatizados para enviar mensajes en momentos clave, como después de que un usuario deja sus datos o tras una primera reunión informativa.
                  <br /><br />
                  Con Beex, puedes realizar un seguimiento automatizado de los leads interesados que no han respondido, asegurando que no se pierda ninguna oportunidad de conversión. Todo este proceso se gestiona desde un solo lugar.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsOne-card-image"
                  src={imageTwoDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
          <div className="container-experience-pandero-cardsTwo-cards-column">
            <section className="container-experience-pandero-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 6px" }}>
              <section>
                <p className="container-experience-pandero-cardsTwo-card-title">Comunica eventos y anuncios por WhatsApp y correo</p>
                <p className="container-experience-pandero-cardsTwo-card-description">
                  Impulsa el alcance de eventos clave, como consultorios en vivo o concursos de fotografía, utilizando canales de alto impacto como WhatsApp y correo electrónico. Automatiza estas comunicaciones para enviar mensajes masivos a los usuarios registrados en tu base de datos.
                  <br /><br />
                  Además de agilizar el trabajo del equipo interno de marketing y reduce costos operativos, reemplazando tácticas tradicionales como PR o campañas en medios digitales para la divulgación de estos eventos. </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsTwo-card-image"
                  src={imageThreeDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
        </div>
      </div>


      <div className="container-experience-pandero-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-pandero-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-pandero-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-pandero-cards-mobile-card-description" dangerouslySetInnerHTML={{ __html: elemento.description }}>
                    
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-pandero-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
