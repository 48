import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide67/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide67/image/img.png"
import slider1 from "@components/pageGuide/guides/guide67/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide67/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide67/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    5 automatizaciones en la comunicación de venta y postventa de un e-commerce  
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    Donde los consumidores tienen acceso a innumerables opciones a solo un clic de distancia, la capacidad de ofrecer una experiencia de compra fluida y eficiente se ha convertido en un factor crucial para la supervivencia y el éxito de cualquier negocio.  
     </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
En este contexto, la automatización de la comunicación en las etapas de venta y postventa emerge como una estrategia fundamental que no solo mejora la eficiencia operativa, sino que también juega un papel clave en la personalización y mejora de la experiencia del cliente.  
<br /><br />
Con la adopción de estas estrategias, no solo optimizarás los recursos internos de tu empresa, sino que también te posicionarás como un líder en experiencia del cliente, capaz de anticipar y superar las expectativas de tus usuarios en cada interacción.
</p>
)

const data = {
  start: {
    support: "Guía | Ecommerce",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Uso de chatbots para preguntas frecuentes.",
      },
      {
        key: 2,
        text: "Cómo crear una experiencia personalizada.",
      },
      {
        key: 3,
        text: "Seguimiento automatizado de pedidos.",
      },
      {
        key: 4,
        text: "Gestión de devoluciones automatizada.",
      },
      {
        key: 5,
        text: "Encuestas de satisfacción y feedback automáticos.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
