/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageFunctionalities/pageChatbotsWhatsapp/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageFunctionalities/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import { TitleUnderlineRightOne, TitleUnderlineRightTwo } from "@components/pageFunctionalities/pageChatbotsWhatsapp/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageFunctionalities/pageChatbotsWhatsapp/titleUnderline/titleUnderlineTestimony"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageFunctionalities/pageChatbotsWhatsapp/titleUnderline/titleUnderlineNavbarVertical"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import NavbarVertical from "@components/sharedComponents/navbarVertical/navbarVertical"
import ImageVerticalOne from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalThree from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/imagesNavbarVertical/imageThree.webp"
import Industries from "@components/pageFunctionalities/pageChatbotsWhatsapp/industries/industries"
import Cta from "@components/pageWebinar/components/cta/cta"

const TemplatePageChatbostWhasapp = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    <TitleUnderlineRightOne underline="Aumenta tu ticket" /> <TitleUnderlineRightTwo underline="de venta" /> con experiencias que generan recordación a los clientes en cada recorrido de compra
  </p>
  const titleTestimony = <p>
    Ayudamos a   <TitleUnderlineTestimony underline="diferentes" />  <TitleUnderlineTestimony underline="sectores" /> a fortalecer la relación con sus clientes por WhatsApp

  </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return concesionarias
      case 1:
        return logistica
      case 2:
        return salud
      case 3:
        return educacion
      case 4:
        return ecommerce
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Concesionarias"
      case 1:
        return "Logística"
      case 2:
        return "Salud"
      case 3:
        return "Educación"
      case 4:
        return "Ecommerce"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  const titleNavbarVertical = <p>
    <TitleUnderlineNavbarVerticalOne underline="Automatizamos" /> <TitleUnderlineNavbarVerticalTwo underline="las gestiones" /> más importantes de las empresas para crear conexiones únicas
  </p>

  const objOne = {
    tab: "1",
    title: "Proceso de Citas y Reservas",
    text: (
      <p>
        Automatiza la programación de citas y reservas, permitiendo a los clientes elegir fechas y horarios a través de una interfaz conversacional fácil de usar, simplificando el proceso y mejorando la satisfacción.
      </p>
    ),
    image: ImageVerticalOne,
  }
  const objTwo = {
    tab: "3",
    title: "Mensajes Interactivos",
    text: (
      <p>
        Implementa mensajes interactivos para enriquecer la experiencia del usuario, permitiendo a los clientes tomar acciones directas dentro de la conversación, como seleccionar opciones en botones o listas de mensajes.
      </p>
    ),
    image: ImageVerticalThree,
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Cta />
        <div className="banner-invisible-ebook" />
        <Header
          path="/productos/"
          location={location}
          bannerCTA={true}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/V32jj-f-_h8?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Cientos de empresas revolucionaron la comunicación con sus clientes por medio de WhatsApp" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <BannerCTA
            title="Crea experiencias conversacionales para mejorar la satisfacción del cliente"
            text="Nuestro equipo comercial está dispuesto para ayudarte a tus necesidades."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <NavbarVertical dataOne={data.navbarVerticalRight} dataTwo={data.navbarVerticalLeft}
            dataSilder={data.navbarVerticalSilder}
            objOne={objOne}
            objTwo={objTwo}
            title={titleNavbarVertical}
            description={''} />
          <BannerCTA
            title="Aumenta las conversiones de tu empresa con interacciones únicas"
            text="Nuestro equipo comercial está dispuesto a ayudarte con tu caso de uso en específico."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Industries data={data.industries} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageChatbostWhasapp
