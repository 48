/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pagePlatcon/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pagePlatcon/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import TitleUnderlineLeft from "@components/pagePlatcon/titleUnderline/titleUnderlineAccordionLeft"
import TitleUnderlineTestimony from "@components/pagePlatcon/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import Operations from "@components/pagePlatcon/operations/operations"
import {TitleUnderline} from "@components/pagePlatcon/operations/titleUnderline" 

const TemplatePagePlatcon = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionLeft = <p><TitleUnderlineLeft underline="Funciones" /> </p>
 const titleTestimony = <p>
  Mejoramos las interacciones de las empresas para crear experiencias conversacionales que <TitleUnderlineTestimony underline="generan conversiones" /></p>
const titleOperations = <p className="container-operations-title">
<TitleUnderline underline="Características" />
</p>
   //Video youtube
   const [video, setVideo] = useState(false)
   const openVideo = value => {
     setVideo(value)
   }

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      default:
        return "Conoce otros casos de éxito"
    }
  }


  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        
        <div>
          <Header
            path="/productos/"
            location={location}
            bannerCTA={false}
            windowsWidth={windowsWidth}
          />
        </div>
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/E0EzysG7DLE?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start location={location} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Únete a las empresas que confían en nosotros" />
          <AccordionLeft data={data.accordionLeft} title={titleAccordionLeft} description={""} />
          <Operations data={data.modules} location={location} title={titleOperations} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePagePlatcon
