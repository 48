import React, { useState, useEffect } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"
import Select from "react-select"
import { Link } from "gatsby"


const URL_API = process.env.URL_API

const IntroHome = ({
  data,
  confirm,
  setConfirm,
  countries,
  location, openVideo
}) => {
  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  //Estado valores inputs
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    workers: "default",
    phone: "",
    codeCountry: {
      value: "Perú",
      label: "Perú",
      image: "https://flagcdn.com/w320/pe.png",
      code: "+51",
    },
    
  })


  const numberWorkers = ["1-4", "5-10", "11-20", "21-30", "40 a más agentes"]

  //Estado validaciones
  const [validations, setValidations] = useState({
    name: "",
    lastname: "",
    email: "",
    workers: "",
    phone: "",
    codeCountry: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const {
      name,
      lastname,
      email,
      workers,
      phone,
      codeCountry,
    } = values
    const Blacklist = [
      "@gmail.com",
      "@hotmail.com",
      "@yahoo.com",
      "@outlook.com",
      "yahoo.es",
      "yahoo.com.mx",
      "icloud.com",
      "gmail.co",
      "hotmail.es",
      "hotmail.it",
      "outlook.com",
      "outlook.es",
    ]

    const validations = {
      name: "",
      lastname: "",
      email: "",
      workers: "",
      phone: "",
      codeCountry: "",
    }
    let isValid = true

    if (!name) {
      validations.name = "Campo requerido"
      isValid = false
    }
    if (name && !/^[a-zA-Z\s]+$/.test(name)) {
      validations.name = "Ingresar solo letras"
      isValid = false
    }
    if (!lastname) {
      validations.lastname = "Campo requerido"
      isValid = false
    }
    if (lastname && !/^[a-zA-Z\s]+$/.test(lastname)) {
      validations.lastname = "Ingresar solo letras"
      isValid = false
    }

    if (!email) {
      validations.email = "Campo requerido"
      isValid = false
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "El formato debe ser el example@mail.com"
      isValid = false
    }
    if (Blacklist) {
      for (let i = 0; i < Blacklist.length; i++)
        if (email.toLowerCase().includes(Blacklist[i]) === true) {
          validations.email = "Ingrese un correo corporativo"
          isValid = false
        }
    }
    if (!phone) {
      validations.phone = "Campo requerido"
      isValid = false
    }
    if (phone && !phone.match(/^[0-9]+$/)) {
      validations.phone = "Ingresar solo numeros"
      isValid = false
    }
    if (workers === "default") {
      validations.workers = "Campo requerido"
      isValid = false
    }
    if (codeCountry === "default") {
      validations.codeCountry = "Campo requerido"
      isValid = false
    }
    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }

    if (
      (name === "name" ||
        name === "lastname") &&
        !/^[a-zA-Z\s]+$/.test(value)
      ) {
      message = "Ingresar solo letras"
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "El formato debe ser el example@mail.com"
    }

    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const { name, lastname, email, workers, phone } = values

  const {
    name: nameVal,
    lastname: lastnameVal,
    email: emailVal,
    workers: workersVal,
    phone: phoneVal,
    codeCountry: codeCountryVal,
  } = validations

  const [gclid, setGclid] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const gclidParam = params.get('gclid');
    if (gclidParam) {
      setGclid(gclidParam);
    }
  }, []);

  //Funcion envio formulario
  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = validateAll()

console.log(isValid)

    //Validar los campos
    if (isValid) {
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
        setConfirm(true)
      }, 3000)
      try {
        const params = new URLSearchParams()
        params.append("firstname", values.name)
        params.append("lastname", values.lastname)
        params.append("email", values.email)
        params.append("workers", values.workers)
        params.append("codeCountry", values.codeCountry.code)
        params.append("phone", values.phone)
        params.append("gclid", gclid)
        await fetch(`${URL_API}/backend/v2/wb/contact-center`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
          body: params,
        })
      } catch (error) {
        console.log(error)
      }
      setValues({
        name: "",
        lastname: "",
        email: "",
        workers: "default",
      })
    } else {
      console.log("noo entrooo")
      return false
    }
  }

  const handleChangeworkers = value => {
    setValues({ ...values, workers: value })
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const [windowsWidth, setWindowsWidth] = useState(350)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 464) {
      setWindowsWidth(270)
    } else {
      setWindowsWidth(350)
    }
  }, [])

  const customStylesworkers = {
    option: provided => ({
      ...provided,
      width: windowsWidth,
      paddingTop: 10,
    }),
    menu: provided => ({
      ...provided,
      width: windowsWidth,
      marginTop: 0,
    }),
    placeholder: provided => ({
      ...provided,
      color: "#c7c7c7",
      marginLeft: 6,
      marginRight: 0,
      textAlign: "left",
    }),
    control: provided => ({
      ...provided,
      width: "97%",
      borderRadius: 8,
      margin: windowsWidth >= 350 ? "11px auto 0" : "11px auto 10px",
      minHeight: 51,
    }),
  }

  const handleChangeCountry = value => {
    setValues({ ...values, codeCountry: value })
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      width: 150,
      paddingTop: 10,
    }),
    menu: (provided) => ({
      ...provided,
      width: 150,
      marginTop: 0,
    }),
    control: provided => ({
      ...provided,
      width: "100%",
      borderRadius: 8,
      marginTop: 11,
      minHeight: 50,
    }),
  }

  return (
    <section className="container-intro-form-contact-ia intro-background-hero-inicio " >
      <div className="section-ebook-intro">
        <div>
          <section className="hero-image-ebook">
            <section className="section-hero-ebook " >
              <h1 className="container-intro-page-casos-de-uso-infoTitle-title">
                {data.title}
              </h1>
              <div className="container-intro-page-casos-de-uso-infoTitle-text">
                {data.textbody}
              </div>
              <div className="container-intro-page-score-infoTitle-button">
                <Link
                  className="container-intro-page-score-infoTitle-buttonOne"
                  to={`/contact-center/precios/${location.search}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver precios
                </Link>
                <button
                  className="container-intro-page-score-infoTitle-buttonTwo"
                  onClick={() => { openVideo(true) }}
                >
                  Ver video
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2683_958)">
                      <path d="M13.2963 8.5915L9.40225 6.45925C8.8975 6.175 8.2975 6.18025 7.79875 6.472C7.2985 6.7645 7.00075 7.28425 7.00075 7.86325V12.1375C7.00075 12.7165 7.2985 13.2362 7.79875 13.5287C8.05375 13.678 8.33425 13.7515 8.614 13.7515C8.8825 13.7515 9.151 13.6833 9.39475 13.546L13.3038 11.4055C13.819 11.116 14.1257 10.591 14.1257 10.0008C14.1257 9.4105 13.819 8.8855 13.297 8.59225L13.2963 8.5915ZM12.5755 10.0938L8.6665 12.235C8.65225 12.2425 8.60875 12.2665 8.55625 12.2343C8.50075 12.202 8.50075 12.154 8.50075 12.1382V7.864C8.50075 7.84825 8.50075 7.80025 8.55625 7.768C8.5765 7.756 8.59525 7.75225 8.61175 7.75225C8.63875 7.75225 8.6605 7.76425 8.674 7.77175L12.568 9.90475C12.5822 9.913 12.625 9.937 12.625 10.0023C12.625 10.0675 12.5815 10.0915 12.5755 10.096V10.0938ZM10 1C5.03725 1 1 5.03725 1 10C1 14.9628 5.03725 19 10 19C14.9628 19 19 14.9628 19 10C19 5.03725 14.9628 1 10 1ZM10 17.5C5.8645 17.5 2.5 14.1355 2.5 10C2.5 5.8645 5.8645 2.5 10 2.5C14.1355 2.5 17.5 5.8645 17.5 10C17.5 14.1355 14.1355 17.5 10 17.5Z" fill="#262626" fill-opacity="0.88" />
                    </g>
                    <defs>
                      <clipPath id="clip0_2683_958">
                        <rect width="18" height="18" fill="white" transform="translate(1 1)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </section>
            <div className="container-suscribir-hero-ebook">
              {confirm ? (
                <section className="section-suscribir-ebook">
                  <div className="container-heroebook-suscribirone">
                    <div className="confirmation">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M19.0002 37.9998C14.1319 37.9998 9.26361 36.1492 5.55719 32.4428C-1.85565 25.03 -1.85565 12.9696 5.55719 5.56205C12.97 -1.84553 25.0303 -1.85079 32.4379 5.56205C39.8455 12.9749 39.8508 25.0352 32.4379 32.4428C28.7368 36.1492 23.8685 37.9998 19.0002 37.9998ZM19.0002 3.80085C15.1045 3.80085 11.2141 5.28341 8.24369 8.24855C2.31341 14.1788 2.31341 23.826 8.24369 29.7563C14.174 35.6813 23.8212 35.6866 29.7514 29.7563C35.6817 23.826 35.6817 14.1788 29.7514 8.24855C26.7916 5.28341 22.8959 3.80085 19.0002 3.80085Z"
                            fill="#F3D134"
                          />
                          <path
                            d="M16.7341 25.477C16.2452 25.477 15.7615 25.293 15.3882 24.9197C14.647 24.1785 14.647 22.9745 15.3882 22.2332L25.5296 12.0919C26.2709 11.3506 27.4748 11.3506 28.2161 12.0919C28.9574 12.8331 28.9574 14.0371 28.2161 14.7783L18.0747 24.9197C17.7067 25.293 17.2178 25.477 16.7341 25.477Z"
                            fill="#F3D134"
                          />
                          <path
                            d="M16.7289 25.4771C16.2716 25.4771 15.8142 25.3141 15.4514 24.9829L9.8471 19.8728C9.06901 19.163 9.01644 17.9643 9.72618 17.1863C10.4359 16.4082 11.6346 16.3556 12.4127 17.0601L18.017 22.165C18.7951 22.8747 18.8476 24.0734 18.1379 24.8514C17.7594 25.2668 17.2494 25.4771 16.7289 25.4771Z"
                            fill="#F3D134"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="38" height="38" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <br /> <br />
                      <p className="text-thanks">¡Gracias! </p>
                      <br />
                      <p>
                      Pronto, un experto de Beex se comunicará contigo para contarte más sobre cómo podemos mejorar tu estrategia omnicanal.
                        <br /> <br />
                        Para sumar, te invitamos a que leas nuestros artículos.  <br /><Link
                          style={{
                            color: "#2C5C82",
                            fontWeight: "600",
                            width: "136px",
                          }}
                          href={"https://beexcc.com/blog/"}
                          type="submit"
                        >
                          Clickeando aquí.
                        </Link>
                      </p>
                    </div>
                  </div>
                </section>
              ) : (
                <div style={{ position: "relative" }}>
                  <svg className="svg-hexagono-formOne"


                    width="498" height="428" viewBox="0 0 498 428" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M66.6927 15.2522C85.4456 -33.0208 133.149 -63.7285 184.855 -60.8105L343.841 -51.8383C395.547 -48.9204 439.493 -13.0406 452.695 37.0358L493.292 191.014C506.495 241.09 485.951 293.973 442.405 322.004L308.509 408.195C264.963 436.226 208.32 433.029 168.205 400.277L44.8558 299.568C4.74045 266.816 -9.72299 211.958 9.02994 163.685L66.6927 15.2522Z" fill="#EEEAFB" />
                  </svg>
                  <div className="section-suscribir-ebook">

                    <div className="container-heroebook-suscribirtwo">
                      <div className="inputs-name">
                        <div>
                          <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                            Nombres
                          </p>
                          <input
                            className="input-hero-ebook"
                            placeholder="Ejemplo: María"
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleChange}
                            onBlur={validateOne}
                          />
                          <div className="error">{nameVal}</div>
                        </div>

                        <div>
                          <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                            Apellidos
                          </p>
                          <input
                            className="input-hero-ebook"
                            placeholder="Ejemplo: Timaná Gonzáles"
                            type="text"
                            name="lastname"
                            value={lastname}
                            onChange={handleChange}
                            onBlur={validateOne}
                          />
                          <div className="error">{lastnameVal}</div>
                        </div>
                      </div>

                      <div>
                        <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                          Correo corporativo
                        </p>
                        <input
                          className="input-hero"
                          placeholder="Ejemplo: maria.mora@beex.pe"
                          type="text"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          onBlur={validateOne}
                        />
                        <div className="error">{emailVal}</div>
                      </div>
                      <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                        Teléfono de contacto
                      </p>
                      <div className="code-phone">
                        <div>
                          <Select
                            options={countries}
                            defaultValue={{
                              value: "Perú",
                              label: "Perú",
                              image: "https://flagcdn.com/w320/pe.png",
                              code: "+51",
                            }}
                            onChange={value => handleChangeCountry(value)}
                            placeholder={"Codigo"}
                            styles={customStyles}
                            formatOptionLabel={country => (
                              <div className="country-option">
                                <img src={country.image} alt="country-image" />
                                <span>{country.code}</span>
                              </div>
                            )}
                          />
                          <div className="error">{codeCountryVal}</div>
                        </div>

                        <div>
                          <input
                            className="input-hero"
                            placeholder="Telefono"
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={handleChange}
                            onBlur={validateOne}
                          />
                          <div className="error">{phoneVal}</div>
                        </div>
                      </div>



                      <div style={{ position: "relative" }}>
                        <p className="container-page-hablemos-form-inputContainer_label" style={{ textAlign: "left" }}>
                          ¿Cuántos agentes usarán la plataforma?
                        </p>
                        <select
                          className={
                            workers === "default"
                              ? "  container-page-hablemos-form-inputContainer_inputOne select-default "
                              : "container-page-hablemos-form-inputContainer_inputOne"
                          }
                          type="text"
                          name="workers"
                          value={workers}
                          onChange={handleChange}
                          onBlur={validateOne}
                          defaultValue={workers}
                        >
                          <option value="default" disabled hidden>
                            Seleccionar
                          </option>
                          {numberWorkers.map((element, index) => {
                            return (
                              <option key={index} value={element}>
                                {element}
                              </option>
                            )
                          })}
                        </select>
                        <i></i>
                        <div className="error">{workersVal}</div>
                      </div>


                      <div style={{ marginTop: "31px" }}>
                        <button
                          className="btn-hero-ebook"
                          onClick={handleSubmit}
                          style={{ display: " inline-flex", gap: "12px" }}
                        >
                          Solicitar Demo  {" "}
                          {spin ? (
                            <Loader
                              type="Oval"
                              color="#5e5e5e"
                              height={20}
                              width={20}
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                      <br />
                      <span style={{ fontSize: '12px' }}>
                        Al registrarte, aceptas los términos y condiciones de nuestra{" "}
                        <a
                          style={{ color: "#2C5C82", textDecoration: "none" }}
                          href="https://beexcc.com/politica-seguridad-privacidad.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Política de Privacidad.{" "}
                        </a>
                      </span>
                    </div>
                  </div>
                  <svg className="svg-hexagono-formTwo" width="303" height="217" viewBox="0 0 303 217" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M49.2591 56.6939C63.1641 20.9003 98.5357 -1.86892 136.874 0.294673L254.76 6.94744C293.099 9.11101 325.684 35.7153 335.474 72.8461L365.575 187.018C375.365 224.149 360.132 263.36 327.844 284.145L228.562 348.054C196.273 368.839 154.274 366.469 124.529 342.184L33.0675 267.51C3.32266 243.224 -7.40176 202.548 6.50321 166.754L49.2591 56.6939Z" fill="#EEEAFB" />
                  </svg>

                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default IntroHome
