/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageFunctionalities/pageVirtualPBX/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageCasosdeUso/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageFunctionalities/pageVirtualPBX/experience/experience"
import { TitleUnderlineRightOne, TitleUnderlineRightTwo } from "@components/pageFunctionalities/pageVirtualPBX/titleUnderline/titleUnderlineAccordionRight"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Operations from "@components/pageHome/operations/operations"
import { TitleUnderlineOperationsVirtualPBXOne, TitleUnderlineOperationsVirtualPBXTwo } from "@components/pageHome/operations/titleUnderline"


const TemplatePageVirtualPBX = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionRight = <p>
    No solo migramos tus operaciones a la nube, también la automatizamos bajo un <TitleUnderlineRightOne underline="enfoque omnicanal" />
  </p>

  const titleOperations = <p className="container-operations-title">
    Nos adaptamos a las <TitleUnderlineOperationsVirtualPBXOne underline="necesidades de" /> <TitleUnderlineOperationsVirtualPBXTwo underline="tu empresa" />, por eso bridamos soluciones a todas tus operaciones

  </p>
  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/u5THqOR1z3k?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Sé parte de las empresas que actualmente mejoran la calidad de sus servicios telefónicos" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Olvídate de antiguas infraestructuras y migra tu central telefónica a la nube"
            text="Contamos con expertos que te ayudarán a migrar sin fricciones."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Es momento de migrar tu central telefónica en la nube"
            text="Junto a nuestro equipo, te ayudamos a mejorar tu canal telefónica con tecnología cloud."
            link="/hablemos/"
            textButton="Contacta con nosotros"
          />
          <Operations data={data.modules} location={location} title={titleOperations} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageVirtualPBX
