/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageColombia/pageChatbots/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageIndustrias/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageColombia/pageChatbots/experience/experience"
import { TitleUnderlineRightTwo } from "@components/pageIndustrias/pageContactCenter/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageIndustrias/pageContactCenter/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import NavbarVertical from "@components/sharedComponents/navbarVertical/navbarVertical"
import ImageVerticalOne from "@components/pageColombia/pageChatbots/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalThree from "@components/pageColombia/pageChatbots/images/imagesNavbarVertical/imageThree.webp"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageFunctionalities/pageWhatsappMasivos/titleUnderline/titleUnderlineNavbarVertical"

const TemplatePageContactCenter = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    <TitleUnderlineRightTwo underline=" No importa" />  <TitleUnderlineRightTwo underline="el volumen" /> <TitleUnderlineRightTwo underline="de mensajes" />, los bots con IA de Beex agilizan la atención de tu empresa sin afectar la experiencia

  </p>
  const titleTestimony = <p>
    Ayudamos a <TitleUnderlineTestimony underline="diferentes sectores " /> a fortalecer la relación con sus clientes con respuestas automatizadas </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return ecommerce
      case 1:
        return concesionarias
      case 2:
        return finanzas
      case 3:
        return salud
      case 4:
        return educacion
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Ecommerce"
      case 1:
        return "Concesionarias"
      case 2:
        return "Finanzas"
      case 3:
        return "Salud"
      case 4:
        return "Educación"
      default:
        return "Conoce otros casos de éxito"
    }
  }


  const titleNavbarVertical = <p>
    <TitleUnderlineNavbarVerticalOne underline="Automatizamos" /> <TitleUnderlineNavbarVerticalOne underline="las gestiones" />  más importantes de las empresas para crear conexiones únicas
  </p>

  const objOne = {
    tab: "1",
    title: "Proceso de Citas y Reservas",
    text: (
      <p>
        Automatiza la programación de citas y reservas, permitiendo a los clientes elegir fechas y horarios a través de una interfaz conversacional fácil de usar, simplificando el proceso y mejorando la satisfacción.
      </p>
    ),
    image: ImageVerticalOne,
  }
  const objTwo = {
    tab: "3",
    title: "Mensajes Interactivos",
    text: (
      <p>
        Implementa mensajes interactivos para enriquecer la experiencia del usuario, permitiendo a los clientes tomar acciones directas dentro de la conversación, como seleccionar opciones en botones o listas de mensajes.
      </p>
    ),
    image: ImageVerticalThree,
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/soluciones/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/QKv9ul8uU8Q?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={false} openVideo={false} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Conoce las empresas de LATAM que ya tienen la mejor CX dentro de la región" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Crea experiencias conversacionales únicas entre tu empresa y tus clientes"
            text="Mira en tiempo real como pueden ayudarte nuestros chatbots en tu servicio, marketing o ventas."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />

          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Aumenta las conversiones de tu empresa con interacciones únicas"
            text="Nuestro equipo comercial está dispuesto a ayudarte con tu caso de uso en específico."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <NavbarVertical dataOne={data.navbarVerticalRight} dataTwo={data.navbarVerticalLeft}
            dataSilder={data.navbarVerticalSilder}
            objOne={objOne}
            objTwo={objTwo}
            title={titleNavbarVertical}
            description={''} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageContactCenter
