/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageColombia/pageContactCenter/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageIndustrias/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageColombia/pageContactCenter/experience/experience"
import { TitleUnderlineRightTwo } from "@components/pageIndustrias/pageContactCenter/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageIndustrias/pageContactCenter/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import audifonos from "@components/sharedComponents/testimonies/images/iconos/audifonos.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import transportes from "@components/sharedComponents/testimonies/images/iconos/transporte.svg"
import ong from "@components/sharedComponents/testimonies/images/iconos/ayuda.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"

const TemplatePageContactCenter = ({ location }) => {

  //Video youtube intro
  const [videoIntro, setVideoIntro] = useState(false)
  const openVideoIntro = value => {
    setVideoIntro(value)
  }

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    Automatiza la gestión de campañas de atención al cliente, telemarketing y cobros <TitleUnderlineRightTwo underline="desde la nube" />
  </p>
  const titleTestimony = <p>Con Beex, Contact Centers en todo el mundo están realmente mejorando <TitleUnderlineTestimony underline="sus resultados" /> </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas
      case 1:
        return audifonos
      case 2:
        return salud
      case 3:
        return transportes
      case 4:
        return ong
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Contact Center & BPO"
      case 2:
        return "Salud"
      case 3:
        return "Transportes"
      case 4:
        return "ONG"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/soluciones/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {videoIntro ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideoIntro(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/AIU7J7Q3Grk?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/AIU7J7Q3Grk?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={false} openVideo={openVideoIntro} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Sé parte de los mejores Contact Center en LATAM que automatizaron sus operaciones con Beex" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Conviértete en el mejor Contact Center de Colombia"
            text="Junto a nuestro equipo, te ayudamos a mejorar los casos de uso de tu operación."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />

          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Migra tu Contact Center a la nube con funcionalidades basadas en IA"
            text="Agenda una sesión y conoce como nuestras soluciones potenciarían tu operación."
            link="/hablemos/"
            textButton="Solicitar asesoría"
          />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageContactCenter
