import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Contactabilidad automatizada y efectiva",
      description: "Aumenta tu tasa de contacto programando interacciones en horarios estratégicos. Automatiza reintentos de llamadas no respondidas, ajusta la frecuencia según el comportamiento de tus clientes y utiliza los canales más efectivos, como llamadas, WhatsApp y más, para conectar de manera eficiente.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Autoservicio con chatbots e IVR",
      description: "Simplifica la implementación de agentes virtuales en voz y chat con los workflows de Beex. Sin necesidad de codificación, automatiza tu servicio al cliente con inteligencia artificial, mejorando tiempos de respuesta y resolución para ofrecer una atención eficiente y autónoma.",
      image: imageTwoMobile,
      background: "#DEF9FB"
    },
    {
      title: "Campañas telefónicas efectivas",
      description: "Optimiza tus operaciones de televentas con marcación progresiva y predictiva impulsada por Machine Learning. Aumenta tu porcentaje de contactabilidad y conecta con más prospectos. Beex es la solución ideal para Contact Centers y BPOs enfocados en ventas.",
      image: imageThreeMobile,
      background: "#EEEAFB"
    },
    {
      title: "Encuestas por teléfono o WhatsApp",
      description: "Diseña y envía encuestas de satisfacción directamente desde Beex, ya sea después de una llamada o a través de WhatsApp en el momento ideal. Analiza los resultados con nuestras herramientas de analítica e inteligencia artificial y transforma el feedback en acciones de mejora efectivas.",
      image: imageFourMobile ,
      background: "#DEF9FB"
    },
  ]

  return (
    <section className="container-experience-contactCenter">
      <p className="container-experience-contactCenter-colombia-title container-experience-contactCenter-title">
        Utilizamos la mejor tecnología para mejorar los servicios de los <TitleUnderlineOne underline="Contact" /> <TitleUnderlineOne underline="Center" /> <TitleUnderlineTwo underline="en Colombia" />
      </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-contactCenter-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-contactCenter-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
          <section>
            <p className="container-experience-contactCenter-cardsOne-card-title">Contactabilidad automatizada y efectiva</p>
            <p className="container-experience-contactCenter-cardsOne-card-description">
              Aumenta tu tasa de contacto programando interacciones en horarios estratégicos. Automatiza reintentos de llamadas no respondidas, ajusta la frecuencia según el comportamiento de tus clientes y utiliza los canales más efectivos, como llamadas, WhatsApp y más, para conectar de manera eficiente.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-contactCenter-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-contactCenter-cardsOne-column">
          {/* columna */}
          <section className="container-experience-contactCenter-cardsOne-card" style={{ background: "#DEF9FB"  }}>
            <section>
              <p className="container-experience-contactCenter-cardsOne-card-title">Autoservicio con chatbots e IVR</p>
              <p className="container-experience-contactCenter-cardsOne-card-description">
                Simplifica la implementación de agentes virtuales en voz y chat con los workflows de Beex. Sin necesidad de codificación, automatiza tu servicio al cliente con inteligencia artificial, mejorando tiempos de respuesta y resolución para ofrecer una atención eficiente y autónoma.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-contactCenter-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-contactCenter-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-contactCenter-cardsTwo-card" style={{ background: "#DEF9FB", padding: "32px 32px 0px" }}>
          <section>
            
            <p className="container-experience-contactCenter-cardsTwo-card-title">Campañas telefónicas efectivas</p>
              <p className="container-experience-contactCenter-cardsTwo-card-description">
                Optimiza tus operaciones de televentas con marcación progresiva y predictiva impulsada por Machine Learning. Aumenta tu porcentaje de contactabilidad y conecta con más prospectos. Beex es la solución ideal para Contact Centers y BPOs enfocados en ventas.
              </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-contactCenter-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-contactCenter-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-contactCenter-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
            <section style={{ padding: "0px 32px" }}>
            <p className="container-experience-contactCenter-cardsTwo-card-title">Encuestas por teléfono o WhatsApp</p>
            <p className="container-experience-contactCenter-cardsTwo-card-description">
              Diseña y envía encuestas de satisfacción directamente desde Beex, ya sea después de una llamada o a través de WhatsApp en el momento ideal. Analiza los resultados con nuestras herramientas de analítica e inteligencia artificial y transforma el feedback en acciones de mejora efectivas.
            </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-contactCenter-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-contactCenter-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-contactCenter-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-contactCenter-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-contactCenter-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-contactCenter-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
