import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="266" height="9" viewBox="0 0 266 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.4536 9C43.9541 8.16452 3.68596 9.09727 0.759033 8.84548C-1.63053 8.50786 2.1882 2.5622 4.40626 1.68666C5.18373 1.37192 6.08696 1.32616 8.15639 1.46922C11.4378 1.73818 53.8668 1.20598 53.8668 1.20598C54.427 1.38909 56.1077 0.77107 56.4164 0.994246C57.3883 1.51499 126.16 0.805402 132.025 0.77679C135.581 0.759622 132.768 1.11441 136.953 0.971352C145.871 0.685228 165.307 0.959909 172.064 0.633728C177.472 0.364771 178.639 0.353335 183.829 0.570789C189.134 0.765353 217.34 0.484949 220.565 0.0672084C221.354 -0.0472411 221.96 -0.00718032 221.96 0.124437C221.96 0.153049 241.842 0.250328 241.934 0.353332C242.082 0.484949 242.688 0.547892 243.248 0.456332C244.289 0.318993 265.452 -0.178864 265.783 0.204542C266.824 1.30326 263.908 5.98424 261.45 7.12301C258.935 8.3419 245.123 6.97424 234.022 7.70671L182.332 7.78111L171.253 8.2389C169.984 7.84405 165.102 8.83976 162.152 8.13017C160.906 7.84977 152.102 7.80971 150.158 8.10728C148.466 8.33618 103.43 7.75821 98.8112 8.55363C90.5792 7.88982 57.1939 8.83977 49.4536 9Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="224" height="9" viewBox="0 0 224 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.6451 9C37.014 8.16452 3.10397 9.09727 0.639186 8.84548C-1.37308 8.50786 1.84269 2.5622 3.71054 1.68666C4.36525 1.37192 5.12586 1.32616 6.86854 1.46922C9.63179 1.73818 45.3615 1.20598 45.3615 1.20598C45.8333 1.38909 47.2486 0.77107 47.5086 0.994246C48.327 1.51499 106.24 0.805402 111.179 0.77679C114.173 0.759622 111.805 1.11441 115.329 0.971352C122.839 0.685228 139.206 0.959909 144.896 0.633728C149.45 0.364771 150.433 0.353335 154.804 0.570789C159.271 0.765353 183.024 0.484949 185.739 0.0672084C186.403 -0.0472411 186.913 -0.00718032 186.913 0.124437C186.913 0.153049 203.656 0.250328 203.734 0.353332C203.859 0.484949 204.369 0.547892 204.841 0.456332C205.717 0.318993 223.538 -0.178864 223.818 0.204542C224.694 1.30326 222.239 5.98424 220.169 7.12301C218.05 8.3419 206.42 6.97424 197.071 7.70671L153.542 7.78111L144.213 8.2389C143.144 7.84405 139.033 8.83976 136.549 8.13017C135.499 7.84977 128.086 7.80971 126.449 8.10728C125.024 8.33618 87.0992 7.75821 83.2094 8.55363C76.2772 7.88982 48.1633 8.83977 41.6451 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineThree = ({ underline }) => (
  <span>
    {underline}
    <svg width="138" height="12" viewBox="0 0 138 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.4244 10.0006C23.1522 9.25365 3.04155 11.3203 1.6066 11.1764C0.333461 10.9213 1.23038 5.04436 2.22 4.12046C2.57087 3.79208 3.02866 3.70412 4.12289 3.79118C5.08257 3.86666 11.6958 3.38968 27.6946 2.32683C28.0081 2.49529 28.7995 1.85555 28.9872 2.0683C29.1304 2.19158 31.4541 2.13717 34.9084 2.01918C38.7843 1.88571 49.3358 1.43737 54.5396 1.25765C62.2198 0.984904 59.3309 1.0376 68.1234 0.801717C69.9626 0.751433 68.5261 1.12679 70.6886 0.965805C75.3008 0.627959 85.3837 0.870585 88.8744 0.568438C91.6741 0.325625 92.2842 0.31071 94.9749 0.536598C97.1274 0.716192 112.316 0.683889 114.03 0.331709C114.443 0.226866 114.757 0.278061 114.748 0.406458C114.927 0.418167 125.037 0.789301 125.081 0.895526C125.152 1.02417 125.457 1.09767 125.754 1.01481C126.292 0.871259 137.282 0.787189 137.434 1.16734C137.861 2.26303 135.99 6.75784 134.668 7.8315C133.283 8.95523 126.47 7.38797 120.619 7.94542C120.619 7.94542 118.969 7.91252 116.493 7.85479C92.3169 7.3351 93.1413 7.64469 88.529 7.98812C87.8753 7.59529 85.4054 8.54823 83.8733 7.87914C83.2372 7.60362 78.7427 7.59005 77.7371 7.8662C77.5216 7.92697 75.9247 7.94448 73.6011 7.95422C71.2865 7.96398 68.2453 7.96038 65.168 8.00693C63.6249 8.03019 62.0728 8.04784 60.6015 8.08248C59.1211 8.11151 57.7125 8.16868 56.4653 8.22633C53.962 8.34162 52.0956 8.48674 51.5208 8.69718C46.9561 8.1362 30.7856 9.62289 26.4244 10.0006Z" fill="#FFBA00"/>
</svg>
  </span>
)