import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide70/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide70/image/img.png"
import slider1 from "@components/pageGuide/guides/guide70/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide70/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide70/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      9 claves para automatizar los procesos de soporte en una empresa
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La automatización de procesos de soporte ayuda a optimizar tareas repetitivas y mejorar la eficiencia. Implementa un sistema de tickets para organizar solicitudes y priorizarlas según su urgencia.  Configura respuestas automáticas para consultas frecuentes y así reducir tiempos de espera.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const textOne = (
  <p>
    Integra chatbots para gestionar interacciones iniciales y derivar casos complejos al equipo adecuado. Utiliza herramientas de seguimiento para monitorear el estado de cada solicitud y mantener el control.
    <br /><br />
    Recuerda que, con esta guía, aprenderás a configurar flujos de trabajo claros y eficientes. Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Lleva tus procesos de soporte al siguiente nivel.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Automatiza la gestión de tickets de soporte.",
      },
      {
        key: 2,
        text: "Implementa chatbots para preguntas frecuentes.",
      },
      {
        key: 3,
        text: "Automatiza el seguimiento de incidentes.",
      },
      {
        key: 4,
        text: "Integra el soporte omnicanal.",
      },
      {
        key: 5,
        text: "Utiliza IA para analizar datos y mejorar el soporte.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
