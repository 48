import React, { useState } from "react"
import ViewOne from "./viewOne"
import ViewTwo from "./viewTwo"
import ViewThree from "./viewThree"
import TitleUnderline from "./titleUnderline"

const Intro = ({
  onClickConfirm,
  countries,
  numberWorkers,
}) => {
  const [dataOne, setDataOne] = useState(false)
  const [dataTwo, setDataTwo] = useState(false)
  const [dataThree, setDataThree] = useState(false)
  const [sectionView, setSectionView] = useState(true)
  const [sectionViewTwo, setSectionViewTwo] = useState(true)

  return (
    <div className="container-page-hablemos">
      <p className="container-page-hablemos-title">
        Habla con nuestro <TitleUnderline underline="equipo de ventas" />
      </p>
      <p className="container-page-hablemos-description">Te pediremos algunos datos importantes para que un experto en soluciones te ayude de la manera más acertada. Tómate tu tiempo, no tardarás más de dos minutos. 😊</p>

      <div>
        {sectionView ? (
          // Vista uno
          <ViewOne
            setSectionView={setSectionView}
            countries={countries}
            setDataOne={setDataOne}
            onClickConfirm={onClickConfirm}
            dataOne={dataOne}
          />
        ) : (
          // Vista dos
         sectionViewTwo ?
            <ViewTwo
              numberWorkers={numberWorkers}
              dataTwo={dataTwo}
              setDataTwo={setDataTwo}
              setSectionView={setSectionView}
              setSectionViewTwo={setSectionViewTwo}
            />
            :
            <ViewThree
              setSectionView={setSectionView}
              countries={countries}
              onClickConfirm={onClickConfirm}
              dataOne={dataOne}
              dataTwo={dataTwo}
              dataThree={dataThree}
             setDataThree={setDataThree}
             setSectionViewTwo={setSectionViewTwo}
            />
        )}
      </div>
    </div>
  )
}

export default Intro
