import React from "react"
import { TitleUnderlineOne } from "./titleUnderline"

const Operations = ({ data }) => {

    return (
        <section className="container-operations-webinar container-operations">
           <p className="container-operations-webinar-title">
           ¿QUÉ <TitleUnderlineOne underline="VERÁS?" />
           </p>
            <div className="container-operations-cards-desktop">
                {data.map((elemento, i) => {
                    return (
                        <div
                            key={i}
                            className="container-operations-cards-desktop-item"
                            style={{ background: elemento.background }}
                        >
                            {elemento.img}
                            <p className="container-operations-cards-desktop-item-info-description">
                                {elemento.text}
                            </p>

                        </div>
                    )
                })}
            </div>
        </section>
    )
}
export default Operations
