/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageIndustrias/pageLogistica/data.jsx"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageIndustrias/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageIndustrias/pageLogistica/componentExperience/experience"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageIndustrias/pageLogistica/titleUnderline/titleUnderlineAccordionLeft"
import { TitleUnderlineCovertionsOne, TitleUnderlineCovertionsTwo } from "@components/pageIndustrias/pageLogistica/titleUnderline/titleUnderlineConvertions"
import { TitleUnderlineExperienceOne, TitleUnderlineExperienceTwo } from "@components/pageIndustrias/pageLogistica/titleUnderline/titleUnderlineExperience"
import TitleUnderlineTestimony from "@components/pageIndustrias/pageLogistica/titleUnderline/titleUnderlineTestimony"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Conversions from "@components/pageIndustrias/components/conversions/conversions"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"

const TemplatePageContactCenter = ({ location }) => {

    //Video youtube
    const [video, setVideo] = useState(false)
    const openVideo = value => {
        setVideo(value)
    }

    //velocidad de entrada de textos y animaciones para desktop y mobile
    const windowsWidth = true


    const titleAccordionRight = <p>
        Agiliza los procesos de envíos y entregas con <TitleUnderlineLeftOne underline="experiencias" /> <TitleUnderlineLeftTwo underline="personalizadas" />
    </p>
    const titleTestimony = <p>
        Beex acompaña a empresas del sector logístico a <TitleUnderlineTestimony underline="automatizar las" /> <TitleUnderlineTestimony underline="interacciones" /> con sus clientes

    </p>
    const titleConvertions = <p className="container-conversions-title">
        Suma chatbots e IVR impulsados con  <TitleUnderlineCovertionsOne underline="IA para" /> <TitleUnderlineCovertionsTwo underline="automatizar" />  la atención sin la intervención de un agente
    </p>

    const titleExperience = <p className="container-experience-ecommerce-title">
        Ofrece experiencias <TitleUnderlineExperienceOne underline="centradas en" />  <TitleUnderlineExperienceTwo underline="el cliente" />  para una mayor fidelización
    </p>



    const iconsSilder = i => {
        switch (i) {
            case 0:
                return finanzas
            case 1:
                return ecommerce
            default:
                return ""
        }
    }
    const titleSilder = i => {
        switch (i) {
            case 0:
                return "Finanzas"
            case 1:
                return "Ecommerce"
            default:
                return "Conoce otros casos de éxito"
        }
    }

    return (
        <div className="fnd">
            <section>
                <BannerCookies />
                <Header
                    path="/soluciones/"
                    location={location}
                    windowsWidth={windowsWidth}
                />
                {video ?
                    <>
                        <button
                            className="button-video-youtube-beex"
                            onClick={() => {
                                openVideo(false)
                            }}
                        >
                            X
                        </button>
                        <div className="container-video-youtube-beex">
                            <div className="container-video-youtube-beex-background"></div>
                            <div className="container-video-youtube-beex-iframe">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/PdO9320RcNs?autoplay=1"
                                    title="Beex Teams"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </> : ""}


                <div className="container">
                    <Start data={data.start} location={location} imageViewResponsive={false} openVideo={false} />
                    <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Únete a las empresas de logística que confiaron en nosotros y lograron mejorar la conexión con sus clientes" />
                    <AccordionLeft data={data.accordionRight} title={titleAccordionRight} description={""} />
                    <BannerCTA
                        title="Reduce la tasa de incidencias de envío en tus procesos logísticos"
                        text="Te ayudamos a mejorar las principales métricas de satisfacción con tus clientes, a través de experiencias únicas adaptadas a tu empresa."
                        link="/hablemos/"
                        textButton="Hablar con un experto"
                    />
                    <Testimonies data={data.testimonies}
                        location={location}
                        title={titleTestimony}
                        iconsSilder={iconsSilder}
                        titleSilder={titleSilder}
                        buttonSilder={true} />
                    <Conversions data={data.conversions} title={titleConvertions} />
                    <Experience data={data.modules} title={titleExperience} />
                    <BannerCTA
                        title="Mejora la comunicación de los tiempos de espera"
                        text="Agilizamos todos los procesos de atención de tu proceso logístico de manera personalizada, con workflows e IA."
                        link="/hablemos/"
                        textButton="Hablar con un experto"
                    />
                    <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
                    <ArticleBlog data={data.articles} home={true} />
                    <BannerContact data={data.contacts} location={location} />
                    <Footer data={data.footer} location={location} />
                    <FooterMobile data={data.footer} location={location} />
                </div>
            </section>
        </div>
    )
}

export default TemplatePageContactCenter
