/* eslint-disable no-undef */
import React from "react"
import TemplatePage from "@components/pageColombia/pageChatbots/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Chatbots con IA para empresas de Colombia [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/co/chatbots-empresas/`}
      />
      <link rel="alternate" hreflang="es-CO" href="https://beexcc.com/co/chatbots-empresas/" />
      <link rel="alternate" hreflang="x-default" href="https://beexcc.com/co/chatbots-empresas/" />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo los chatbots con IA de Beex ayudan a las empresas en Colombia a optimizar sus interacciones y mejorar la experiencia de los clientes." />

      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Chatbots con IA para empresas de Colombia"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/co/plataforma-atencion-cliente/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Descubre cómo los chatbots con IA de Beex ayudan a las empresas en Colombia a optimizar sus interacciones y mejorar la experiencia de los clientes."
      />
    </Helmet>
    <TemplatePage location={location} />
  </div>
)

export default IndexPage

