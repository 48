import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide74/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide74/image/img.png"
import slider1 from "@components/pageGuide/guides/guide74/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide74/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide74/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Cómo realizar seguimiento de leads por WhatsApp en una empresa de seguros 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    El seguimiento de leads por WhatsApp es una forma eficaz de mantener la comunicación activa con prospectos. Organiza tu lista de contactos para personalizar los mensajes según sus necesidades específicas. Asegúrate de redactar mensajes claros que presenten información útil sobre productos y servicios. 
   </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const textOne = (
  <p>
Recuerda que, con esta guía, aprenderás a usar herramientas para automatizar el envío de mensajes y realizar un seguimiento más eficiente de cada prospecto. También sabrás cómo programar recordatorios o responder preguntas frecuentes de manera ágil. 
<br /><br />
Por último, descubre más pasos, ejemplos prácticos y recomendaciones descargándola. Mejora tu estrategia de seguimiento y logra más cierres. 
 </p>
)

const data = {
  start: {
    support: "Guía | Seguros",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "WhatsApp para generar relaciones duraderas con tus leads.",
      },
      {
        key: 2,
        text: " Implementa mensajes personalizados con palabras clave.",
      },
      {
        key: 3,
        text: " Usa parámetros UTM en chatbots web que redireccionan a WhatsApp.",
      },
      {
        key: 4,
        text: "  Cómo enviar mensajes de seguimiento por medio de esta app.",
      },
      {
        key: 5,
        text: "Mide y optimiza tu estrategia de seguimiento.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
