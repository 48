/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageCasosdeUso/pageCobros/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageCasosdeUso/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageCasosdeUso/pageCobros/experience/experience"
import { TitleUnderlineRightOne } from "@components/pageCasosdeUso/pageCobros/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageCasosdeUso/pageCobros/titleUnderline/titleUnderlineTestimony"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageCasosdeUso/pageCobros/titleUnderline/titleUnderlineNavbarVertical"
import monedas from "@components/sharedComponents/testimonies/images/iconos/monedas.svg"
import factura from "@components/sharedComponents/testimonies/images/iconos/factura.svg"
import billetera from "@components/sharedComponents/testimonies/images/iconos/billetera.svg"
import campana from "@components/sharedComponents/testimonies/images/iconos/campana.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"

const TemplatePageCobros = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    Beex asegura la <TitleUnderlineRightOne underline="recuperación" /><TitleUnderlineRightOne underline=" de tus carteras" /> utilizando la mejor tecnología

  </p>
  const titleTestimony = <p>
    Nuestras soluciones se adaptan a diferentes segmentos de cobros.
    Conoce <TitleUnderlineTestimony underline="los resultados" />
  </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return billetera
      case 1:
        return factura
      case 2:
        return campana
      case 3:
        return monedas
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Multicartera"
      case 1:
        return "Castigada"
      case 2:
        return "Preventiva"
      case 3:
        return "Mora temprana"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/soluciones/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/5Z1-OqzbesM?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Ellos ya perfeccionaron los resultados de sus cobranzas y alcanzaron sus objetivos" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Mejora la contactabilidad de tus carteras con IA y Machine Learning"
            text="Mira en tiempo real como nuestras soluciones mejoran tus procesos de cobros."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Logra alcanzar tus objetivos de recupero"
            text="Conoce como mejorar tus métricas de cobro más importantes con nuestra tecnología."
            link="/hablemos/"
            textButton="Contacta con nosotros"
          />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageCobros
