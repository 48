/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageSoporte/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageSoporte/components/start/start"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import BecauseWe from "@components/pageSoporte/components/becauseWe/becauseWe"
import Testimonies from "@components/pageSoporte/components/testimonios/testimonios"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import Compare from "@components/pageSoporte/components/compare/compare"

const TemplatePageSoporte = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  //Video youtube 
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/3-BoJg1_pf8?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}

        <div className="container">
          <Start data={data} location={location} />
          <BecauseWe />
          <Compare data={data.compare} />
          <Testimonies data={data.testimonies} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageSoporte
