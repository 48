import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide75/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide75/image/img.png"
import slider1 from "@components/pageGuide/guides/guide75/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide75/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide75/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Casos de uso de chatbots para la prospección por WhatsApp en universidades 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    Los chatbots son una herramienta útil para optimizar la prospección de estudiantes interesados en programas académicos. Automatizan las respuestas a preguntas frecuentes, lo que facilita el acceso a información sobre procesos de admisión, requisitos y fechas importantes. 
   </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const textOne = (
  <p>
Con esta guía, podrás saber cómo utilizar chatbots para recopilar datos básicos de los interesados y segmentar a los prospectos según sus preferencias académicas. 
<br /><br />
Por último, descubre más detalles, pasos prácticos y configuraciones recomendadas descargándola. Mejora tu estrategia de prospección con WhatsApp. 
</p>
)

const data = {
  start: {
    support: "Guía | Educación",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Recordatorios automatizados para eventos.",
      },
      {
        key: 2,
        text: "Nutrición de leads con contenido relevante."
      },
      {
        key: 3,
        text: "Asesoría automatizada para el proceso de admisión.",
      },
      {
        key: 4,
        text: "Feedback y seguimiento post-conversación.",
      },
      {
        key: 5,
        text: "Importancia de la prospección por WhatsApp.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
