/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Aviva from "@components/sharedComponents/customersLogos/logos/Aviva.svg"
import PulsoSalud from "@components/sharedComponents/customersLogos/logos/PulsoSalud.svg"
import Idisac from "@components/sharedComponents/customersLogos/logos/Idisac.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageAbm/pageSanitas/image/imageOne.png"
import ImageAccordionLeftTwo from "@components/pageAbm/pageSanitas/image/imageTwo.png"
import ImageAccordionLeftThree from "@components/pageAbm/pageSanitas/image/imageThree.png"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageSalud.jpg"
import imgStart from "@components/pageAbm/pageSanitas/image/imageLogo.svg"
import imgBanner from "@components/pageAbm/pageSanitas/image/banner.png"

const titleStart = (<p className="guide__title">
    Hola, Sanitas. Supera la meta de lograr <span style={{ color: '#00B2A9' }}>55,000 afiliados en EPS</span>   automatizando la prospección y seguimiento de leads
</p>
)

const descripcionStart = (<p>
Descubre cómo Beex puede optimizar la gestión de leads y mejorar la experiencia en Sanitas con tecnología omnicanal impulsada por IA.
<br /><br />
Centraliza la comunicación en un único número de WhatsApp, automatiza tu prospección con IA chatbots, personaliza el recontacto con workflows y mejora la prospección con integraciones con tu CRM/ERP. Todo, enfocado en simplificar y potenciar tu gestión comercial.
</p>)

const data = {
    start: {
        title: titleStart,
        textbody: descripcionStart,
        img: imgStart,
        banner: imgBanner,
        inputText: "Puesto en Sanitas",
    },
    customersDesktop: [
        Rimac,
        Synlab,
        Auna,
        Aviva,
        PulsoSalud,
        Idisac
    ],
    customersTablet: {
        arrayOne: [ Rimac,
            Synlab,
            Auna,
            Aviva,
            PulsoSalud,
            Idisac],
    },
    contacts: {
        title: "Sanitas, automatiza cada fase de tu estrategia de prospección",
        textbody: "Estás a un paso de revolucionar las interacciones con tus clientes potenciales. Agenda una reunión para que uno de nuestros agentes se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    accordionRight: [
        {
            title: "Mejorar el primer contacto",
            description: "<p>Cuando los clientes potenciales dejan sus datos en el formulario de Sanitas, el contacto posterior se realiza desde un número diferente al oficial, lo que puede generar confusión. Centralizar el primer contacto en un solo canal, como WhatsApp, simplifica la experiencia. <br /><br /> Con Beex, puedes automatizar este proceso enviando una plantilla de WhatsApp que recopile información inicial del cliente. Esto permite que los agentes inicien la conversación con datos clave, optimizando el tiempo y la efectividad de la prospección. </p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Unifica tus canales de WhatsApp",
            description: "<p>Actualmente, Sanitas cuenta con dos números de WhatsApp separados para EPS y SCTR, que puede limitar a los clientes potenciales que buscan otros seguros. Integrar estos servicios en un único número centralizado y gestionado con Beex no solo mejoraría la experiencia, sino que también permitiría a los clientes elegir el plan adecuado desde un bot. <br /><br /> Esto optimiza el flujo de atención, asegura una comunicación más clara y aumenta la satisfacción de los usuarios.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Implementa bots para pre-prospección",
            description: "<p>Un chatbot con inteligencia artificial en WhatsApp puede ser clave para mejorar la pre-prospección de clientes potenciales. Este bot recopila información relevante mediante preguntas automatizadas, como el tipo de seguro de interés o datos específicos del cliente. <br/> <br/>Con esta información procesada previamente, los agentes de ventas pueden ofrecer soluciones más personalizadas y efectivas, aumentando las tasas de conversión y optimizando la gestión comercial. </p>",
            image: ImageAccordionLeftThree,
        },
    ],
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    bannerVideo: {
        title: "¿Cómo automatizar y brindar experiencias únicas en el sector salud?",
        description: "<p>Descubre cómo simplificar y mejorar la atención médica con automatización inteligente. Explora nuevas formas de proporcionar experiencias únicas en el sector salud. Optimiza procesos y maximiza la eficiencia para un cuidado más efectivo y personalizado. <br /><br /> Conoce cómo la tecnología transforma la manera en que interactuamos y cuidamos de la salud de todos.</p>",
        image: imageVideo
    },
}

export default data
