/* eslint-disable react/jsx-key */
import React from "react"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageColombia/pageWhatsappPrecio/conversions/images/imageOne.webp"
import imageConversionsTwo from "@components/pageColombia/pageWhatsappPrecio/conversions/images/imageTwo.webp"
import imageConversionsThree from "@components/pageColombia/pageWhatsappPrecio/conversions/images/imageThree.webp"
import imageConversionsFour from "@components/pageColombia/pageWhatsappPrecio/conversions/images/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageWhatsa.jpg"

import whatsapp from "@components/pageFunctionalities/pageWhatsappPrecio/intro/image/whatsapp.svg"

//image conexion
import conexion1 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/conexion1.png"
import conexion2 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/conexion2.png"
import conexion3 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/conexion3.png"
import conexion4 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/conexion4.png"

import { TitleUnderline } from "./intro/titleUnderline"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Wally from "@components/sharedComponents/customersLogos/logos/Wally.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageColombia/pageWhatsappPrecio/images/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageColombia/pageWhatsappPrecio/images/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageColombia/pageWhatsappPrecio/images/imageThree.webp"


//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/que-es-whatsapp-business-api.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/whatsapp-business-api-cloud.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/proveedores-whatsapp-api.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/plantillas-mensajes-whatsapp-business.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/tendencias-whatsapp-2024.jpg"

import bandera from "@components/pageColombia/pageWhatsappPrecio/intro/image/🇨🇴.png"


const textBody = (
  <p>
    Deja de preocuparte por pagar costos elevados para acceder a la API de WhatsApp. Con Beex, solo pagas lo que consumes. Descubre las tarifas de mensajes en Colombia y cómo esta plataforma puede transformar la comunicación de tu empresa.
    <br /><br />
    Con la API de WhatsApp y Beex, podrás automatizar la atención a tus clientes con bots de IA, enviar notificaciones personalizadas, configurar workflows inteligentes, gestionar mensajes masivos y mucho más.
  </p>
)

let data = {
  start: {
    logo: whatsapp,
    title: <h1 className="title-whatsapp">
      Conoce los precios de WhatsApp Business API <TitleUnderline underline="Colombia" /> <img src={bandera} alt="" />
    </h1>,
    textbody: textBody,
    textbutton: "Accede a la API de WhatsApp aquí",
    linkbutton: "/whatsapp-business-api/contacto-whatsapp-api/",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  bannerVideo: {
    title: "¿Cómo obtener la API de WhatsApp Business?",
    description: "<p>En el siguiente video, aprenderás a solicitar la API de WhatsApp Business de la manera más sencilla. Pero, primero, crea una cuenta de Beex para seguir este tutorial. <br /> <br />    Si quieres tener una atención mucho más personalizada, puedes solicitar una reunión con uno de nuestros agentes </p>",
    image: imageVideo
  },
  conversions: [
    {
      title: "Configura mensajes interactivos",
      img: imageConversionsOne,
      text:
        "Mejora la experiencia de tus clientes permitiendo interactuar con el contenido de tus mensajes de una forma más dinámica. Añade botones y listas de mensajes cuando configures chatbots o mensajes masivos.",
    },
    {
      title: "Despliega mensajes masivos",
      img: imageConversionsTwo,
      text:
        "La API de WhatsApp Business te permite enviar mensajes simultáneos y de forma masiva a toda tu base de contactos. Usa el poder del marketing conversacional, enviando ofertas, notificaciones y más. Asegura que tus clientes te lean gracias al 98% de tasa de apertura del canal.",
    },
    {
      title: "Mejor rendimiento en Ads",
      img: imageConversionsThree,
      text:
        "Gestiona mejor los altos volúmenes de atención que provienen de las campañas de Meta Ads, Click to WhatsApp. Configura respuestas automáticas que eviten el abandono de una atención por falta de respuesta, y prospecta mejor a tus leads con las automatizaciones con IA de Beex y WhatsApp.",
    },
    {
      title: "Enruta la atención con otros canales",
      img: imageConversionsFour,
      text:
        "Ya sea de teléfono o desde cualquier canal, podrás enrutar la atención de un punto de contacto a WhatsApp y viceversa. De esta forma, evitas que los usuarios tengan que repetir la información brindada al momento de cambiar de canal.",
    },
  ],
  customersDesktop: [
    Rimac,
    Culqui,
    Unifef,
    Auna,
    Astara,
    Wally,
    Proempresa,
    Civa,
    G4S,
    Savar,
    Escampa,
    Royal
  ],
  customersTablet: {
    arrayOne: [Rimac,
      Culqui,
      Unifef,
      Auna,
      Astara,
      Wally
    ],
    arrayTwo: [Proempresa,
      Civa,
      G4S,
      Savar,
      Escampa,
      Royal],
  },
  accordionLeft: [
    {
      title: "Utiliza la IA de Beex",
      description: "<p>Crea respuestas únicas para cada usuario utilizando inteligencia artificial. Crea chatbots potenciados por IA con ChatGPT y clasifica y segmenta a tus clientes por intensiones de compra en base a las conversaciones que surgen en una atención.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Automatiza mensajes con workflows",
      description: "<p>Configura tus flujos de conversación por WhatsApp para reducir las gestiones repetitivas de tus agentes, sin necesidad de codificar. Ofrece respuestas rápidas y personalizadas de manera simultánea, sin la necesidad de una intervención humana, a todos tus usuarios.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integra WhatsApp con CRM y más",
      description: "<p>Utiliza la API de WhatsApp Business para integrarla con las plataformas y bases de datos existentes dentro de tu empresa, como Salesforce, HubSpot, Zoho y más. Utiliza tus datos para mejorar el envío de mensajes y hacerlos más personalizados.</p>",
      image: ImageAccordionLeftThree,
    },
  ],
  articles: [
    {
      image: article1,
      title: "WhatsApp Business API: Qué es y cómo optimiza tus estrategias de ventas",
      textbody:
        "Descubre que es WhatsApp Business API, cómo conseguirla y la forma en que te ayuda a mejorar las estrategias para aumentar tus ventas.",
      link: "https://beexcc.com/blog/que-es-whatsapp-business-api/",
      card: true,
    },
    {
      image: article2,
      title: "WhatsApp Cloud API vs Business API: Entiende sus diferencias",
      textbody:
        "Entérate cuáles son las diferencias entre WhatsApp Cloud API vs Business API y cuál es la mejor opción para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-business-api-cloud/",
      card: true,
    },
    {
      image: article3,
      title: "3 mejores proveedores de WhatsApp Business API para tu empresa",
      textbody:
        "Hicimos un listado de los mejores proveedores de WhatsApp API para tu empresa. Así podrás tener una mejor elección",
      link: "https://beexcc.com/blog/proveedores-whatsapp-api/",
    },
    {
      image: article4,
      title: "6 plantillas de mensajes para WhatsApp Business API para vender",
      textbody:
        "Si buscas plantillas de mensajes para WhatsApp Business, aquí te damos 6 que te harán más fácil el cómo vender por WhatsApp.",
      link: "https://beexcc.com/blog/plantillas-mensajes-whatsapp-business/",
    },
    {
      image: article5,
      title: "Tendencias de WhatsApp para las empresas en el 2024",
      textbody:
        "Descubre 7 tendencias de WhatsApp para empresas que marcarán la pauta este 2024. ¡Entra aquí y mantente al día para impulsar tus ventas!",
      link: "https://beexcc.com/blog/tendencias-whatsapp-2024/",
    },
  ],
}

export default data
