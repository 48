import React from "react"
import TemplatePageGuide from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide75/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Casos de uso de chatbots para la prospección por WhatsApp en universidades
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/prospección-whatsapp-universidades/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content=" Conoce cómo los chatbots en WhatsApp pueden transformar la prospección de leads en universidades, optimizando la captación y atención de estudiantes."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Casos de uso de chatbots para la prospección por WhatsApp en universidades"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/prospección-whatsapp-universidades/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia75.jpg`}
      />
      <meta
        property="og:description"
        content=" Conoce cómo los chatbots en WhatsApp pueden transformar la prospección de leads en universidades, optimizando la captación y atención de estudiantes."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Educación"
      location={location}
      typeGuide="guide75"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
