/* eslint-disable no-undef */
import React from "react"
import TemplatePage from "@components/pageColombia/pageContactCenter/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Software para Contact Center en Colombia con IA [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/co/software-contact-center/`}
      />
      <link rel="alternate" hreflang="es-CO" href="https://beexcc.com/co/software-contact-center/" />
      <link rel="alternate" hreflang="x-default" href="https://beexcc.com/co/software-contact-center/" />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce cómo funciona el software para contact center con IA en Colombia y sus aplicaciones en la gestión de llamadas y atención al cliente."
      />

      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Software para Contact Center en Colombia con IA"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/co/software-contact-center/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Conoce cómo funciona el software para contact center con IA en Colombia y sus aplicaciones en la gestión de llamadas y atención al cliente."
      />
    </Helmet>
    <TemplatePage location={location} />
  </div>
)

export default IndexPage

