import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="183" height="10" viewBox="0 0 183 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.8344 9.85778C30.0752 8.91618 2.52143 9.85521 0.519912 9.56837C-1.11362 9.18745 1.52237 2.59044 3.04333 1.62299C3.57648 1.27517 4.19462 1.2265 5.60989 1.39046C7.85383 1.69723 36.8844 1.20848 36.8844 1.20848C37.267 1.4133 38.4193 0.730674 38.6296 0.979393C39.2925 1.56034 86.3463 0.938188 90.3593 0.920578C92.7921 0.9101 90.8664 1.29751 93.7299 1.14867C99.8324 0.852319 113.129 1.20451 117.753 0.858426C121.454 0.572663 122.252 0.562775 125.803 0.816939C129.432 1.04595 148.73 0.802583 150.938 0.346225C151.478 0.220967 151.892 0.266944 151.892 0.413184C151.892 0.444975 165.494 0.601133 165.557 0.715803C165.658 0.862402 166.072 0.933803 166.456 0.833426C167.168 0.683343 181.649 0.181339 181.874 0.608144C182.582 1.83145 180.569 7.02546 178.883 8.28481C177.157 9.63304 167.713 8.08003 160.115 8.86705L142.432 8.84589L124.75 8.82474L117.168 9.3066C116.301 8.86482 112.957 9.95935 110.942 9.16379C110.091 8.84922 104.068 8.78343 102.737 9.10936C101.578 9.3596 70.7683 8.60852 67.605 9.48116C61.9755 8.72369 39.1307 9.69847 33.8344 9.85778Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="373" height="10" viewBox="0 0 373 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69.1571 9.24433C61.4699 8.28884 5.15417 9.12623 1.06206 8.832C-2.27824 8.44506 3.08531 1.85768 6.19055 0.895831C7.27901 0.549969 8.54229 0.503582 11.4357 0.672766C16.0234 0.98782 75.3593 0.606165 75.3593 0.606165C76.1421 0.812398 78.4948 0.134014 78.9256 0.383512C80.2826 0.966917 176.457 0.518346 184.659 0.51554C189.632 0.514037 185.697 0.894348 191.55 0.75607C204.022 0.482228 231.202 0.88348 240.652 0.554451C248.216 0.282338 249.847 0.275394 257.105 0.54266C264.523 0.785058 303.968 0.612889 308.478 0.164669C309.582 0.0414022 310.429 0.0889086 310.429 0.235149C310.429 0.26694 338.233 0.473283 338.36 0.588184C338.567 0.735158 339.415 0.808089 340.198 0.709125C341.654 0.561669 371.251 0.113082 371.713 0.540725C373.164 1.76665 369.068 6.9533 365.626 8.20645C362.104 9.54833 342.795 7.96046 327.267 8.71946L291.125 8.63307L254.982 8.54668L239.487 9.00058C237.714 8.55559 230.883 9.6378 226.761 8.8348C225.019 8.51708 212.708 8.42906 209.989 8.75009C207.621 8.99606 144.644 8.13131 138.182 8.99228C126.673 8.21404 79.9821 9.10455 69.1571 9.24433Z" fill="#FFA700" />
    </svg>
  </span>
)