import score from "@components/pageHablemos/imgDemoandHablemos/product/score.svg"
import ticker from "@components/pageHablemos/imgDemoandHablemos/product/ticker.svg"
import platcon from "@components/pageHablemos/imgDemoandHablemos/product/platcon.svg"
import whatsapp from "@components/pageHablemos/imgDemoandHablemos/solutions/whatsapp.svg"
import chatbots from "@components/pageHablemos/imgDemoandHablemos/solutions/chatbots.svg"
import envio from "@components/pageHablemos/imgDemoandHablemos/solutions/envios.svg"
import marcadores from "@components/pageHablemos/imgDemoandHablemos/solutions/marcadores.svg"
import voice from "@components/pageHablemos/imgDemoandHablemos/solutions/voice.svg"
import siptrunk from "@components/pageHablemos/imgDemoandHablemos/solutions/siptrunk.svg"
import voz from "@components/pageHablemos/imgDemoandHablemos/channels/voz.svg"
import channelWhatsapp from "@components/pageHablemos/imgDemoandHablemos/channels/whatsapp.svg"
import messenger from "@components/pageHablemos/imgDemoandHablemos/channels/messenger.svg"
import instagram from "@components/pageHablemos/imgDemoandHablemos/channels/instagram.svg"
import webchat from "@components/pageHablemos/imgDemoandHablemos/channels/webchat.svg"
import correo from "@components/pageHablemos/imgDemoandHablemos/channels/correo.svg"
import sms from "@components/pageHablemos/imgDemoandHablemos/channels/sms.svg"
import google from "@components/pageHablemos/imgDemoandHablemos/redes/google.svg"
import youtube from "@components/pageHablemos/imgDemoandHablemos/redes/youtube.svg"
import blog from "@components/pageHablemos/imgDemoandHablemos/redes/blog.svg"
import linkedin from "@components/pageHablemos/imgDemoandHablemos/redes/linkedin.svg"
import recomendacion from "@components/pageHablemos/imgDemoandHablemos/redes/recomendacion.svg"
import messengerFace from "@components/pageHablemos/imgDemoandHablemos/redes/messeger.svg"
import tiktok from "@components/pageHablemos/imgDemoandHablemos/redes/tiktok.svg"
import podcast from "@components/pageHablemos/imgDemoandHablemos/redes/podcast.svg"






// Los "id" deben corresponder a los valores de "solucion" en Hubspot, para crear un nuevo valor, se debe solicitar el ID al administrador de Hubspot

export const optionsChannels = [
  {
    id: "voz",
    image: voz,
    description: "Voz",
  },
  {
    id: "whatsapp",
    image: channelWhatsapp,
    description: "WhatsApp Business",
  },
  {
    id: "correo",
    image: correo,
    description: "Correo electrónico",
  },
  {
    id: "sms",
    image: sms,
    description: "SMS",
  },
  {
    id: "webchat",
    image: webchat,
    description: "Webchat",
  },
  {
    id: "messenger",
    image: messenger,
    description: "Facebook Messenger",
  },
  {
    id: "instagram",
    image: instagram,
    description: "Instagram",
  },
]

// Los "id" deben corresponder a los valores de "solucion" en Hubspot, para crear un nuevo valor, se debe solicitar el ID al administrador de Hubspot
export const optionsRedes = [
  {
    id: "google",
    image: google,
    description: "Búsqueda en Google",
  },
  {
    id: "youtube",
    image: youtube,
    description: "Video en Youtube",
  },
  {
    id: "blog",
    image: blog,
    description: "Artículo de Blog",
  },
  {
    id: "linkedin",
    image: linkedin,
    description: "LinkedIn",
  },
  {
    id: "recomendacion",
    image: recomendacion,
    description: "Recomendación",
  },
  {
    id: "facebook",
    image: messengerFace,
    description: "Facebook/Instagram",
  },
  {
    id: "tiktok",
    image: tiktok,
    description: "Video en Tiktok",
  },
  {
    id: "webinar_podcast",
    image: podcast,
    description: "Webinar/Podcast",
  },
]



// Los "id" deben corresponder a los valores de "solucion" en Hubspot, para crear un nuevo valor, se debe solicitar el ID al administrador de Hubspot
export const optionsProduct = [
  {
    id: "score_v2",
    title: "Contact Center",
    image: score,
    description: "Contact Center omnicanal en la nube",
    type: "product",
  },
  {
    id: "ticker",
    title: "Conversations",
    image: ticker,
    description: "Atención al cliente omnicanal y chatbots",
    type: "product",
  },
  {
    id: "platcon",
    title: "Validation",
    image: platcon,
    description: "Validación de teléfonos",
    type: "product",
  },
  {
    id: "whatsapp_business_api",
    image: whatsapp,
    description: "WhatsApp Business API",
  },
  {
    id: "chatbots",
    image: chatbots,
    description: "Chatbots",
  },
  {
    id: "whatsapp_broadcast",
    image: envio,
    description: "Envíos masivos de WhatsApp",
  },
  {
    id: "marcador_predictivo",
    image: marcadores,
    description: "Marcador progresivo/predictivo",
  },
  {
    id: "sip_trunk",
    image: siptrunk,
    description: "SIP Trunk",
  },
  {
    id: "voice_api",
    image: voice,
    description: "Voice API",
  },
]