import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide69/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
       6 mejores prácticas de email marketing para inmobiliarias
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/email-marketing-inmobiliarias/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce las mejores 6 prácticas de email marketing para inmobiliarias y logra aumentar tus resultados."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content=" 6 mejores prácticas de email marketing para inmobiliarias"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/email-marketing-inmobiliarias/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia69.jpg`}
      />
      <meta
        property="og:description"
        content="Conoce las mejores 6 prácticas de email marketing para inmobiliarias y logra aumentar tus resultados."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Inmobiliarias "
      location={location}
      typeGuide="guide69"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
