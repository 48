/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageCasosdeUso/pageMarketing/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageCasosdeUso/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageCasosdeUso/pageMarketing/experience/experience"
import { TitleUnderlineRightOne } from "@components/pageCasosdeUso/pageMarketing/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageCasosdeUso/pageMarketing/titleUnderline/titleUnderlineTestimony"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageCasosdeUso/pageMarketing/titleUnderline/titleUnderlineNavbarVertical"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import gas from "@components/sharedComponents/testimonies/images/iconos/gas.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import NavbarVertical from "@components/sharedComponents/navbarVertical/navbarVertical"
import ImageVerticalOne from "@components/pageCasosdeUso/pageMarketing/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalThree from "@components/pageCasosdeUso/pageMarketing/images/imagesNavbarVertical/imageThree.webp"


const TemplatePageAtencion = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    Beex te ayuda a mejorar tus tasas de adquisición, conversión y ventas con <TitleUnderlineRightOne underline="tecnología" /><TitleUnderlineRightOne underline=" omnicanal" /> </p>
  const titleTestimony = <p>
    Beex ha mejorado la experiencia de compra de empresas en diferentes sectores, conoce <TitleUnderlineTestimony underline="los resultados" />
  </p>

  const titleNavbarVertical = <p>
    Utiliza la API de WhatsApp Business integrada <TitleUnderlineNavbarVerticalOne underline="con ChatGPT" />  para mejorar la experiencia de compra de tus clientes
  </p>

  const objOne = {
    tab: "1",
    title: "Envío de ofertas y promociones",
    text: (
      <p>
        Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
      </p>
    ),
    image: ImageVerticalOne,
  }
  const objTwo = {
    tab: "1",
    title: "Envío de ofertas y promociones",
    text: (
      <p>
        Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
      </p>
    ),
    image: ImageVerticalThree,
  }

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return ecommerce
      case 1:
        return concesionarias
      case 2:
        return finanzas
      case 3:
        return educacion
      case 4:
        return gas
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Ecommerce"
      case 1:
        return "Concesionarias"
      case 2:
        return "Finanzas"
      case 3:
        return "Educación"
      case 4:
        return "Consumo"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/soluciones/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/nf7HU0cEFBc?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title=" Ellos han logrado una experiencia de compra más personalizada que les permitió fidelizar mejor" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Mejora tus indicadores de marketing y ventas con tecnología omnicanal e IA"
            text="Agenda una sesión con un experto y mira en tiempo real el uso de nuestras plataformas."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />

          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Brinda experiencias únicas en cada paso del funnel de ventas"
            text="Incrementa tu ticket de compra y mejora la retención de clientes con nosotros."
            link="/hablemos/"
            textButton="Hablar con un experto"
          />
          <NavbarVertical dataOne={data.navbarVerticalRight} dataTwo={data.navbarVerticalLeft}
            dataSilder={data.navbarVerticalSilder}
            objOne={objOne}
            objTwo={objTwo}
            title={titleNavbarVertical}
            description={<p className="container-navbar-vertical-description">Mejora la comunicación con tus clientes por medio de esta app y fideliza mejor que tus competidores</p>} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageAtencion
