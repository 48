import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide73/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide73/image/img.png"
import slider1 from "@components/pageGuide/guides/guide73/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide73/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide73/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    6 estrategias de marketing y ventas para venta de autos 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    El marketing y las ventas son claves para mejorar los resultados en la venta de autos. Define tu público objetivo para crear mensajes que conecten con sus intereses y necesidades. Usa campañas digitales para promocionar modelos y ofertas especiales en canales donde estén tus prospectos. 
   </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const textOne = (
  <p>
Aprovecha la segmentación para enviar mensajes personalizados y aumentar la posibilidad de conversión. Recuerda que, con esta guía, aprenderás a usar herramientas que optimicen la atención al cliente y mejoren la experiencia de compra.  
<br /><br />
Además, descubre cómo combinar estrategias online y offline para maximizar resultados. Por último, encuentra ejemplos prácticos y pasos claros descargándola. Mejora tus estrategias y acelera tus ventas. 
</p>
)

const data = {
  start: {
    support: "Guía | Concesionarias",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Aprovecha el poder de las redes sociales para generar leads.",
      },
      {
        key: 2,
        text: "Utiliza email marketing automatizado para nutrir a tus leads.",
      },
      {
        key: 3,
        text: "Crea una experiencia omnicanal para mejorar la atención al cliente.",
      },
      {
        key: 4,
        text: "Realiza campañas de remarketing.",
      },
      {
        key: 5,
        text: "Realidad Virtual para vender autos.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
