/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageScore/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageScore/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import BannerLogin from "@components/pageScore/bannerLogin/bannerLogin"
import Integrations from "@components/pageScore/integrations/integrations"
import Experience from "@components/pageScore/experience/experience"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageScore/titleUnderline/titleUnderlineAccordionLeft"
import TitleUnderlineRight from "@components/pageScore/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageTicker/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import audifonos from "@components/sharedComponents/testimonies/images/iconos/audifonos.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import transportes from "@components/sharedComponents/testimonies/images/iconos/transporte.svg"
import ong from "@components/sharedComponents/testimonies/images/iconos/ayuda.svg"
import VideoDesktop from "@components/pageScore/images/videoScore.mp4"
import BannerNegro from "@components/pageScore/bannerNegro/bannerNegro"
import Industries from "@components/pageScore/industries/industries"
import BannerEbook from "@components/pageScore/bannerEbook/bannerEbook"
import PopoverLogin from "@components/pageScore/bannerLogin/popoverLogin"

const TemplatePageScore = ({ location }) => {
  //Video youtube
  const [videoTicker, setVideoTicker] = useState(false)
  const openVideoTicker = value => {
    setVideoTicker(value)
  }


  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionLeft = <p>Con Beex, <TitleUnderlineLeftOne underline="automatiza" /> <TitleUnderlineLeftTwo underline="con IA" />  los procesos entrantes y salientes de tu Contact Center</p>
  const titleAccordionRight = <p>
    El software de Contact Center de Beex hace <TitleUnderlineRight underline="programables" />  y  <TitleUnderlineRight underline="automatizados" />  tus procesos de atención al cliente, telemarketing y cobros
  </p>
  const titleTestimony = <p>Con Beex, Contact Centers en todo el mundo están realmente mejorando <TitleUnderlineTestimony underline="sus resultados" /> </p>


  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas
      case 1:
        return audifonos
      case 2:
        return salud
      case 3:
        return transportes
      case 4:
        return ong
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Contact Center & BPO"
      case 2:
        return "Salud"
      case 3:
        return "Transportes"
      case 4:
        return "ONG"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  const [popoverLogin, setPopoverLogin] = useState(false)

  return (
    <>
      <PopoverLogin
        popoverLogin={popoverLogin}
        setPopoverLogin={setPopoverLogin}
      />
      <div className="fnd">
        <section>
          <BannerCookies />
          <Header
            path="/productos/"
            location={location}
            windowsWidth={windowsWidth}
          />
          <BannerLogin setPopoverLogin={setPopoverLogin} />
          {videoTicker ? (
            <>
              <button
                className="button-video-youtube-beex"
                onClick={() => {
                  openVideoTicker(false)
                }}
              >
                X
              </button>
              <div className="container-video-youtube-beex">
                <div className="container-video-youtube-beex-background"></div>
                <div className="container-video-youtube-beex-iframe">
                  <video className="container-video-youtube-beex-iframe-video" controls autoPlay={true}>
                    <source src={VideoDesktop} type="video/mp4" />
                  </video>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="container">
            <Start data={data.start} location={location} openVideo={setVideoTicker} />
            <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Estas empresas ya tienen su Contact Center en la nube con Beex" />
            <AccordionLeft data={data.accordionLeft} title={titleAccordionLeft} description={" Suma tecnología omnicanal inteligente para todo tipo de campañas. Consigue brindar la mejor experiencia al cliente en tu industria, ahora mismo."} />
            <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
            <BannerCTA
              title="Atiende mejor, vende más y fideliza a tus clientes con un software escalable"
              text="Agenda una reunión con uno de nuestros expertos y mira nuestras soluciones en acción."
              link="/hablemos/"
              textButton="Acceder a Demo"
            />
            <AccordionRight data={data.accordionRight} list={true}
              title={titleAccordionRight}
              description={" Conoce como Beex es capaz de implementar automatizaciones con IA que mejoran la experiencia de los clientes. Sin necesidad de codificar, hazlo rápido, simple y eficaz."}
            />
            <BannerNegro />
            <Industries data={data.industries} />
            <Experience data={data.modules} location={location} />

            <BannerCTA
              title="Haz feliz a tus agentes y clientes con la mejor experiencia en Contact Center"
              text="Habla con un representante de ventas de Beex y conoce que hace diferente y especial a Beex"
              link="/hablemos/"
              textButton="Agendar reunión"
            />
            <Integrations location={location} />
            <ArticleBlog data={data.articles} home={true} />
            <BannerEbook />
            <Footer data={data.footer} location={location} />
            <FooterMobile data={data.footer} location={location} />
          </div>
        </section>
      </div>
    </>
  )
}

export default TemplatePageScore
