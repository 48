import React from "react"
import { Link } from "gatsby"
import helpCustomer from "./images/imagenContact.webp"
import contactOne from "./images/contactOne.webp"
import contactTwo from "./images/contactTwo.webp"
import contactThree from "./images/contactThree.webp"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import TitleUnderline from "./titleUnderline"

const IntroHome = ({ data, location, openVideo }) => {
  return (
    <section className="container-intro-page-ticker">
      <div className="container-intro-page-ticker-infoTitle">
        <h1 className="container-intro-page-ticker-infoTitle-title">
          <TitleUnderline underline="Centraliza tus " /> <TitleUnderline underline="conversaciones " />  y automatiza el recorrido de tus clientes con chatbots e IA
        </h1>
        <div className="container-intro-page-ticker-infoTitle-text">
          Potencia la comunicación con tus clientes unificando tus canales de atención, como voz, WhatsApp, correo y más. Crea flujos automatizados de servicio al cliente, marketing y ventas, e intégralos con nuestra potente estructura de APIs.</div>
        <div className="container-intro-page-ticker-infoTitle-button">
          <Link
            className="container-intro-page-ticker-infoTitle-buttonOne"
            to={`/hablemos/${location.search}`}
          >
            Solicitar Demo
          </Link>
          <button
            className="container-intro-page-ticker-infoTitle-buttonTwo"
            onClick={() => { openVideo(true) }}
          >
            Ver video
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2683_958)">
                <path d="M13.2963 8.5915L9.40225 6.45925C8.8975 6.175 8.2975 6.18025 7.79875 6.472C7.2985 6.7645 7.00075 7.28425 7.00075 7.86325V12.1375C7.00075 12.7165 7.2985 13.2362 7.79875 13.5287C8.05375 13.678 8.33425 13.7515 8.614 13.7515C8.8825 13.7515 9.151 13.6833 9.39475 13.546L13.3038 11.4055C13.819 11.116 14.1257 10.591 14.1257 10.0008C14.1257 9.4105 13.819 8.8855 13.297 8.59225L13.2963 8.5915ZM12.5755 10.0938L8.6665 12.235C8.65225 12.2425 8.60875 12.2665 8.55625 12.2343C8.50075 12.202 8.50075 12.154 8.50075 12.1382V7.864C8.50075 7.84825 8.50075 7.80025 8.55625 7.768C8.5765 7.756 8.59525 7.75225 8.61175 7.75225C8.63875 7.75225 8.6605 7.76425 8.674 7.77175L12.568 9.90475C12.5822 9.913 12.625 9.937 12.625 10.0023C12.625 10.0675 12.5815 10.0915 12.5755 10.096V10.0938ZM10 1C5.03725 1 1 5.03725 1 10C1 14.9628 5.03725 19 10 19C14.9628 19 19 14.9628 19 10C19 5.03725 14.9628 1 10 1ZM10 17.5C5.8645 17.5 2.5 14.1355 2.5 10C2.5 5.8645 5.8645 2.5 10 2.5C14.1355 2.5 17.5 5.8645 17.5 10C17.5 14.1355 14.1355 17.5 10 17.5Z" fill="#262626" fill-opacity="0.88" />
              </g>
              <defs>
                <clipPath id="clip0_2683_958">
                  <rect width="18" height="18" fill="white" transform="translate(1 1)" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <Link
          className="container-intro-page-ticker-infoTitle-link"
          to={`/conversations/precios/${location.search}`}
        >
          Mira nuestros planes
        </Link>


      </div>
      <div className="container-intro-page-ticker-image">
        <img
          src={helpCustomer}
          alt="customer"
          loading="lazy"
        />
      </div>

      <section className="container-intro-page-ticker-silder">
        <Slider
          dots={true}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          speed={500}
          autoplaySpeed={18000}
          pauseOnFocus={false}
          draggable={true}
          pauseOnHover={false}
          initialSlide={0}
          arrows={true}
        >
          <img
            src={contactOne}
            alt="customer"
            className="image-help-customermobile"
            loading="lazy"
          />
          <img
            src={contactTwo}
            alt="customer"
            className="image-help-customermobile"
            loading="lazy"
          />
          <img
            src={contactThree}
            alt="customer"
            className="image-help-customermobile"
            loading="lazy"
          />
        </Slider>
      </section>
    </section>
  )
}

export default IntroHome
